import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication-service';
import {RouteUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let canActivate = this.authenticationService.isAuthenticated();
        if (canActivate) {
            const routeRoles = RouteUtil.getRouteData(route, 'authSpecificRoles', []);
            if (routeRoles.length > 0) {
                const hasRole = this.authenticationService.userHasRole(routeRoles);
                if (!hasRole) {
                    // TODO SNOEI-303
                    // No permission to access the page
                    // this.router.navigate(['no-permission'], {});
                    canActivate = false;
                }
            }
        } else {
            const redirectUrl = `/${route.url.join('/').replace(/^\//i, '')}`;

            // Redirect to login
            this.router.navigate(['/login'], {
                queryParams: {
                    redirectUrl,
                },
            });
        }
        return canActivate;
    }

}
