<div class="modal-title">{{ 'configurator:modal:add_version:title' | translate }}</div>
<div class="modal-template">
    <p>
        {{ 'configurator:modal:add_version:description' | translate }}
    </p>
</div>
<div class="modal-footer">
    <div class="text-error" *ngIf="serverError">
        {{ 'configurator:modal:add_version:server_error' | translate }}
    </div>
    <button (click)="finish()" class="btn-primary btn-block btn-large" [attr.disabled]="savingForm ? 'disabled' : null">
        <div *ngIf="savingForm" class="loading-spinner-inline"></div>
        {{ 'configurator:modal:add_version:button' | translate }}
    </button>
</div>
