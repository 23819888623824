import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {environment} from '../../environments/environment';
import {IConfiguratorMessageType} from '../../interfaces/configurator-message';
import {NotificationService} from '../notification-service';
import {MessageUtil} from '../../utils/message.util';


@Injectable()
export class ConfiguratorInterfaceService {

    private isViewerReady = false;
    private onViewerReadyPromises: any[] = [];
    public configEventObservable = new Subject<any>();

    constructor(
        private notificationService: NotificationService,
    ) {
        window.addEventListener('message', this.handleReceivedMessage.bind(this));

        this.setViewerReady(false);

        this.receiveMessage().subscribe((data) => {
            if (data.action === 'configurator' && data.initialized) {
                this.setViewerReady(true);

                this.onViewerReadyPromises.forEach((resolve) => {
                    resolve();
                });
            }
        });
    }

    public receiveMessage(): Observable<any> {
        return this.configEventObservable.asObservable();
    }

    public sendMessage(message: IConfiguratorMessageType) {
        // First create the payload
        const payload = {
            action: message.action,
            params: [...message.params],
        };

        const frames = document.querySelectorAll('iframe') as NodeListOf<HTMLIFrameElement>;
        frames.forEach((frame) => {
            frame.contentWindow.postMessage(JSON.stringify(payload), environment.configuratorInterfaceOrigin);
        });
    }

    public requestState() {
        this.sendMessage({action: 'requestState', params: []});
    }

    private handleReceivedMessage(event) {
        if (event.data && MessageUtil.isJson(event.data)) {
            const data = JSON.parse(event.data);

            if (MessageUtil.valid(data)) {

                // if theres a 'config' error, throw error
                if (data.action === 'config-error') {
                    // This one is for the developer, therefore we wont notify the user..
                    throw new Error(data.message);
                }
                if (data.action === 'error') {
                    this.notificationService.openError('', data.message);
                    return;
                }

                this.configEventObservable.next(data);
            }
        }
    }

    public setViewerReady(ready: boolean) {
        this.isViewerReady = ready;

        if (!ready) {
            this.onViewerReadyPromises = [];

        }
    }

    public viewerReady(): Promise<void> {
        return new Promise((resolve) => {
            if (this.isViewerReady) {
                resolve();
            } else {
                this.onViewerReadyPromises.push(resolve);
            }
        });
    }
}
