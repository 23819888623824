<div class="input-container">
    <div class="input-measurement" data-measurement="m">
        <input type="number" [min]="min" [max]="max" [step]="inputStep" [(ngModel)]="shownValue" (change)="changedValue()">
    </div>
    <div class="input-buttons">
        <button class="input-button" [class.disabled]="inputValue <= min" (click)="changedValue('-')">
            <i class="icon">minus</i>
        </button>
        <button class="input-button" [class.disabled]="inputValue >= max" (click)="changedValue('+')">
            <i class="icon">plus</i>
        </button>
    </div>
</div>
