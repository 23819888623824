import {Injectable} from '@angular/core';
import {ApiService} from '../api-service/api-service.service';
import {IOrderConfigurationData} from '../../interfaces/order';
import {OrderConfigurationDataMapper} from '../order-configuration-data-mapper/order-configuration-data-mapper';
import {LocalStorage} from 'ngx-webstorage';
import {Subject} from 'rxjs/internal/Subject';
import {debounceTime} from 'rxjs/operators';
import {AuthenticationService} from '../authentication-service';
import {AuthRoles} from '../../enums';

/**
 * Class for sending and receiving price updates from the server
 */
@Injectable()
export class PriceCalculatorService {

    private loadingSubject: Subject<boolean>;
    private debounceSubject: Subject<any>;
    private priceSubject: Subject<any>;

    private orderConfigurationData: IOrderConfigurationData;
    @LocalStorage('affiliateId') private readonly affiliateId;

    constructor(
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private orderConfigurationDataMapperService: OrderConfigurationDataMapper,
    ) {
        this.loadingSubject = new Subject();
        this.debounceSubject = new Subject();
        this.priceSubject = new Subject();

        this.debounceSubject.pipe(
            debounceTime(1000)
        ).subscribe(( requestData ) => {
            this.orderConfigurationData = requestData.configurationData;
            return this.apiService.post(requestData.url, this.orderConfigurationDataMapperService
                .forPost(this.orderConfigurationData)).then((data) => {
                this.loadingSubject.next(false);
                this.priceSubject.next( data );
            }, () => {
                this.loadingSubject.next(false);
                this.priceSubject.next( false );
            });
        });
    }

    public onLoading(): Subject<boolean> {
        return this.loadingSubject;
    }

    public onPriceChange(): Subject<boolean> {
        return this.priceSubject;
    }

    /**
     * Calculate the price using the given price json
     */
    public calculatePrice(orderConfigurationData: IOrderConfigurationData) {
        const isDealer = this.authenticationService.userHasRole([AuthRoles.DEALER]);
        const isAdmin = this.authenticationService.userHasRole([AuthRoles.ADMIN]);

        if (isDealer || isAdmin) {
            let url = '/pricecalculation';
            if (this.affiliateId !== '' && this.affiliateId !== null) {
                url += `?affiliate=${this.affiliateId}`;
            }

            this.loadingSubject.next(true);
            this.debounceSubject.next({url: url, configurationData: orderConfigurationData});
        }
    }

    /**
     * Get the last sent price json
     */
    public getLastSentPriceJson(): IOrderConfigurationData {
        return this.orderConfigurationDataMapperService.forPost(this.orderConfigurationData);
    }

}
