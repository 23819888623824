import {OrderStatuses} from '../interfaces/order';
import {OrderStatus} from '../enums';

export class OrderUtil {

    public static isReadOnly(orderStatus: OrderStatuses): boolean {
        return orderStatus === OrderStatus.CANCELED || orderStatus === OrderStatus.ORDERED || orderStatus === OrderStatus.PROCESSED;
    }

    public static blueprintRequestCanBeMade(orderStatus: OrderStatuses): boolean {
        return orderStatus === OrderStatus.ORDERED;
    }
}
