import {ElementRef, Injectable} from '@angular/core';
import {AccordionComponent} from '../components/accordion/accordion.component';

@Injectable({
    providedIn: 'root'
})
export class AccordionService {

    private accordionItems: AccordionComponent[] = [];

    constructor() {
        window.addEventListener('resize', this.resizeItems.bind(this));
    }

    public addItem(item: AccordionComponent): void {
        this.accordionItems.push(item);
    }

    public openItem(item: AccordionComponent): void {
        this.accordionItems.forEach((accordionItem) => {
            this.closeItem(accordionItem);
        });

        this.setContentHeight(item, this.getContentHeight(item));
        item.open = true;
    }

    public closeItem(item: AccordionComponent): void {
        this.setContentHeight(item, 0);
        item.open = false;
    }

    public removeItem(item: AccordionComponent): void {
        this.accordionItems = this.accordionItems.filter((accordionItem) => {
            return accordionItem !== item;
        });
    }

    private getDomElement(element: ElementRef): HTMLDivElement {
        return element.nativeElement;
    }

    private setContentHeight(item: AccordionComponent, height: number | string): void {
        this.getDomElement(item.contentWrapperRef).style.height = typeof height === 'string' ? height : `${height}px`;
    }

    private getContentHeight(item: AccordionComponent): number {
        return this.getDomElement(item.contentRef).scrollHeight;
    }

    private resizeItems(): void {
        this.accordionItems.forEach((accordionItem) => {
            if (accordionItem.open) {
                this.setContentHeight(accordionItem, this.getContentHeight(accordionItem));
            }
        });
    }

}
