import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InfoService} from '../services/info-service/info-service.service';
import {ColorService} from '../services/color-service/color-service.service';
import {SessionStorage} from 'ngx-webstorage';
import {LoadingSpinnerService} from '../services/loading-spinner.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    @SessionStorage() public readonly loadingSpinnerVisible: boolean;
    private DEFAULT_LOCALE = 'en';

    constructor(
        private translate: TranslateService,
        private infoService: InfoService,
        private colorService: ColorService,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {
        translate.setDefaultLang(infoService.getCachedDealerInfo().locale);

        loadingSpinnerService.show();
        infoService.init().then((dealerInformation) => {
            let locale = this.DEFAULT_LOCALE;
            if (dealerInformation.locale != null) {
                locale = dealerInformation.locale;
            }

            translate.use(locale);
            loadingSpinnerService.hide();
        });
    }

}
