<a [routerLink]="getRouterLinkForStep(prevStep)" class="prev" [ngClass]="{ 'disabled' : prevStep < 1 }"><i class="icon">arrowleft</i></a>
<a [routerLink]="getRouterLinkForStep(1)" [ngClass]="{ 'active': currentStep === 1 }"><i class="icon">type</i></a>
<a [routerLink]="getRouterLinkForStep(2)" [ngClass]="{ 'active': currentStep === 2 }"><i class="icon">dimensions</i></a>
<a [routerLink]="getRouterLinkForStep(3)" [ngClass]="{ 'active': currentStep === 3 }"><i class="icon">snow</i></a>
<a [routerLink]="getRouterLinkForStep(4)" [ngClass]="{ 'active': currentStep === 4 }"><i class="icon">isolationroof</i></a>
<a [routerLink]="getRouterLinkForStep(5)" [ngClass]="{ 'active': currentStep === 5 }"><i class="icon">door</i></a>
<a [routerLink]="getRouterLinkForStep(6)" [ngClass]="{ 'active': currentStep === 6 }"><i class="icon">color</i></a>
<a [routerLink]="getRouterLinkForStep(nextStep)" class="next" [ngClass]="{ 'disabled' : currentStep === 6 }"><i class="icon">arrowright</i></a>
<a (click)="onFinish()" class="finish" tabindex="0"><i class="icon">mail</i></a>
<app-unredo-buttons class="header-undo-redo"></app-unredo-buttons>
