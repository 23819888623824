import {Directive, HostListener, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FormUtil} from '../utils';

@Directive({
    selector: 'input[appNumberOnly]'
})
export class NumberOnlyDirective {

    constructor(
        @Self() private ngControl: NgControl,
    ) {
    }

    @HostListener('input') onInput() {
        FormUtil.numberOnlyInput(this.ngControl.control);
    }

}
