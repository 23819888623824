import {Pipe, PipeTransform} from '@angular/core';
import {FormUtil} from '../utils';

@Pipe({
    name: 'convertMillimeterToMeter'
})
export class ConvertMillimeterToMeterPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number): number {
        return FormUtil.convertMillimeterToMeter(value);
    }

}
