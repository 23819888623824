import {Component, OnInit} from '@angular/core';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {environment} from '../../environments/environment';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NotificationService} from '../../services/notification-service';

@Component({
    selector: 'app-three-d-viewer-component',
    templateUrl: './three-d-viewer.component.html',
    styleUrls: ['./three-d-viewer.component.scss']
})
export class ThreeDViewerComponent implements OnInit {

    public origin: SafeUrl;

    constructor(
        private configuratorInterface: ConfiguratorInterfaceService,
        private notificationService: NotificationService,
        sanitizer: DomSanitizer,
    ) {
        // The DOM wants a safe URL, so therefore we need to "sanitize" the url..
        this.origin = sanitizer.bypassSecurityTrustResourceUrl(environment.configuratorUrl);
    }

    ngOnInit() {
        this.configuratorInterface.setViewerReady(false);

        // Turn the screenshot mode on for PDF viewpoint finding
        if (!!environment.screenshotMode) {
            this.setScreenshotMode(environment.screenshotMode);
        }
    }

    /**
     * Changes the canvas and adds margin bars to make it easier to find the right viewpoint
     *
     * @param viewpoint
     * @private
     */
    private setScreenshotMode(viewpoint: string) {
        // Only enable screenshot mode if we have a correct viewpoint
        if (viewpoint !== 'front' && viewpoint !== 'back') {
            console.error('Only front and back viewpoints are allowed for the screenshot mode');
            return;
        }

        // Add left margin bar to
        const leftMargin = viewpoint === 'front' ? 0.13 : 0.3;
        const viewportElement = document.getElementById('configurator-frame');
        viewportElement.style.width = '1190px';
        viewportElement.style.height = '841px';
        const width = viewportElement.offsetWidth;
        const leftBar = document.createElement('div');
        leftBar.style.left = '0';
        leftBar.style.top = viewportElement.offsetTop + 'px';
        leftBar.style.width = (width * leftMargin) + 'px';
        leftBar.style.height = viewportElement.clientHeight + 'px';
        leftBar.style.background = 'rgba(255,0,0,0.5)';
        leftBar.style.position = 'absolute';
        leftBar.style.zIndex = '12';
        document.body.appendChild(leftBar);

        // Add the right margin bar
        const rightMargin = viewpoint === 'front' ? 0.16 : 0.3;
        const rightBar = document.createElement('div');
        rightBar.style.left = width - (width * rightMargin) + 'px';
        rightBar.style.top = viewportElement.offsetTop + 'px';
        rightBar.style.width = (width * rightMargin) + 'px';
        rightBar.style.height = viewportElement.clientHeight + 'px';
        rightBar.style.background = 'rgba(255,0,0,0.5)';
        rightBar.style.position = 'absolute';
        rightBar.style.zIndex = '12';
        document.body.appendChild(rightBar);

        // Copy the camera viewpoint onto the clipboard when the camera position and rotation are given
        this.configuratorInterface.receiveMessage().subscribe(action => {
            if (action.action === 'getScreenshotCameraPosition') {
                console.log( action );
                const temporaryInput = document.createElement('input') as HTMLInputElement;
                document.body.appendChild(temporaryInput);

                temporaryInput.value = `{width: ${action.width}, depth: ${action.depth}, ` +
                    `pos: {x: ${action.pos.x}, y: ${action.pos.y}, z: ${action.pos.z}}, ` +
                    `rot: {x: ${action.rot.x}, y: ${action.rot.y}, z: ${action.rot.z}}}`;
                temporaryInput.select();

                document.execCommand('copy');
                document.body.removeChild(temporaryInput);

                this.notificationService.openSuccess('Camera posities gekopieerd!', 1000);
            }
        });

        // Capture camera viewpoint after pressing A, and sets it with Q
        document.addEventListener('keyup', (event) => {
            if (event.key === 'a') {
                this.configuratorInterface.sendMessage({
                    action: 'getScreenshotCameraPosition',
                    params: []
                })
            } else if (event.key === 'q') {
                this.configuratorInterface.sendMessage({
                    action: 'setScreenshotCameraPosition',
                    params: [{
                        side: environment.screenshotMode
                    }]
                })
            }
        })
    }
}
