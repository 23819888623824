import {Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {AccordionService} from '../../services/accordion.service';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public icon: string;
    @Input() public name: string;
    @Input() public open = false;

    @ViewChild('contentWrapper') public contentWrapperRef: ElementRef;
    @ViewChild('content') public contentRef: ElementRef;

    constructor(
        private accordionService: AccordionService,
    ) {
    }

    ngOnInit() {
        this.accordionService.addItem(this);
    }

    ngAfterViewInit() {
        if (this.open) {
            this.openItem();
        }
    }

    ngOnDestroy() {
        this.accordionService.removeItem(this);
    }

    public toggleItem() {
        if (this.open) {
            this.closeItem();
        } else {
            this.openItem();
        }
    }

    private openItem(): void {
        this.accordionService.openItem(this);
    }

    private closeItem(): void {
        this.accordionService.closeItem(this);
    }

}
