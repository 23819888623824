<div class="modal-title">{{ 'configurator:modal:offer-request:title' | translate }}</div>
<div class="modal-template">
    <p>{{ 'configurator:modal:offer-request:disclaimer' | translate }}</p>
    <img class="blueprint-preview" src="../../assets/images/pdf-example.png" />
</div>
<div class="modal-footer">
    <button (click)="finish()" class="btn-primary btn-large" [ngClass]="{'btn-block-half': !this.savingForm, 'btn-block': this.savingForm}" [attr.disabled]="savingForm ? 'disabled' : null">
        <div *ngIf="savingForm" class="loading-spinner-inline"></div>
        <ng-container *ngIf="savingForm">
            {{ 'configurator:modal:offer-request:button' | translate }}
        </ng-container>
        <ng-container *ngIf="!savingForm">
            {{ 'configurator:modal:offer-request:button_show' | translate }}
        </ng-container>
    </button>
    <button *ngIf="!savingForm" (click)="download()" class="btn-outline btn-block-half btn-large">
        {{ 'configurator:modal:offer-request:button_download' | translate }}
    </button>
</div>
