import {Component, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {IDealerInformation} from '../../interfaces/information';
import {OrderService} from '../../services/order-service/order-service.service';
import {IOrder} from '../../interfaces/order';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtil} from '../../utils';

@Component({
    selector: 'app-page-dealer-offers-component',
    templateUrl: './page-dealer-offers.component.html',
    styleUrls: ['./page-dealer-offers.component.scss']
})
export class PageDealerOffersComponent implements OnInit {

    @LocalStorage() private readonly dealerInfo: IDealerInformation;

    public loadingOrders: boolean;
    public loadingError: boolean;
    public orders: IOrder[];
    public searchForm: UntypedFormGroup;
    public selectedColumn = 'id';
    public orderBy = 'desc';

    constructor(
        private orderService: OrderService
    ) {
    }

    ngOnInit() {
        // Create the reactive form
        this.searchForm = new UntypedFormGroup({
            query: new UntypedFormControl(''),
        });

        // Load all orders as default.
        this.searchOrders('', this.selectedColumn, this.orderBy);
    }

    onKeydown(event) {
        event.stopPropagation();
        this.searchOrders(this.searchForm.value.query, this.selectedColumn, this.orderBy);
        this.orders = [];
    }

    public searchOrders(query: string, selectedColumn: string, orderBy: string): void {
        this.loadingOrders = true;
        this.loadingError = false;
        this.orderService.searchOrders(query, selectedColumn, orderBy)
            .then((orders) => {
                this.orders = orders;
            })
            .catch(() => {
                this.loadingError = true;
            })
            .finally(() => {
                this.loadingOrders = false;
            });
    }

    public selectColumn(column: string, orderBy: string): void {
        orderBy = 'asc';
        if (this.selectedColumn === column) {
            orderBy = this.orderBy === 'asc' ? 'desc' : 'asc';
        }
        this.selectedColumn = column;
        this.orderBy = orderBy;
        this.searchOrders(this.searchForm.value.query, column, orderBy);
    }
}
