<ng-container *ngIf="steps.length === 2 && !forceSteps">
    <div class="input-container" [ngClass]="{'active-sliding': sliderActive}">
        <div class="tooltip-box">
            <div class="input-slider-tooltip" [ngStyle]="{left: getTooltipMargin()}" [attr.data-balloon]="currentValue + ' ' + unit" data-balloon-pos="up" data-balloon-visible></div>
        </div>
        <input class="slider" type="range" [min]="steps[0]" [max]="steps[1]" [(ngModel)]="currentValue" [value]="currentValue" (input)="activeSlider()" (change)="changedValue(currentValue)">
    </div>
</ng-container>

<ng-container *ngIf="steps.length > 2 || forceSteps">
    <div class="input-container">
        <div class="slider-back">
            <div *ngFor="let step of steps; let i = index;"
                 [ngStyle]="{width: getStepMargin(i)}"
                 class="slider-progress{{i === currentValue ? ' selected' : ''}}">
                &nbsp;
            </div>
        </div>
        <input class="slider" type="range" step="1" [min]="0" [max]="steps.length - 1" [(ngModel)]="currentValue" [value]="currentValue" (change)="changedValue(currentValue)">
        <div class="steps-box">
            <span
                *ngFor="let step of steps; let i = index;"
                [ngStyle]="{left: getStepMargin(i)}"
                class="{{i === currentValue ? 'selected' : ''}}"
            >
                <strong *ngIf="i === currentValue">{{ step + ' ' + unit }}</strong>
            </span>
        </div>
    </div>
</ng-container>
