import {AbstractControl, UntypedFormGroup} from '@angular/forms';

export class FormValidation {

    private currentFormValidation: UntypedFormGroup[] = [];

    public assignFormValidation(forms: UntypedFormGroup[]) {
        this.currentFormValidation = forms;
    }

    private getFieldControl(field: string): AbstractControl {
        for (const form of this.currentFormValidation) {
            if (form.controls[field]) {
                return form.controls[field];
            }
        }
    }

    public isFormFieldValid(field: string): boolean {
        const fieldControl = this.getFieldControl(field);
        return !fieldControl.dirty || fieldControl.valid;
    }

    public getFormFieldError(field: string): string {
        const fieldControl = this.getFieldControl(field);
        return fieldControl.errors ? Object.keys(fieldControl.errors).find((key) => fieldControl.errors[key]) : '';
    }

    public isFormValid(): boolean {
        let valid = 0;
        this.currentFormValidation.forEach((form) => {
            valid += form.valid ? 1 : 0;
        });
        return valid === this.currentFormValidation.length;
    }

}
