import {Directive, ElementRef, HostListener, OnInit, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormUtil} from '../utils';

@Directive({
    selector: 'input[appFormatNumber]'
})
export class FormatNumberDirective implements OnInit {

    constructor(
        @Self() private ngControl: NgControl,
        private elementRef: ElementRef,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.formatInput();
    }

    @HostListener('input') onInput() {
        this.formatInput();
    }

    private formatInput() {
        FormUtil.numberFormatterInput(this.ngControl.control, this.translate.getDefaultLang());
    }

}
