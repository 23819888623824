import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-sidebar-property-component',
    templateUrl: './sidebar-property.component.html',
    styleUrls: ['./sidebar-property.component.scss']
})
export class SidebarPropertyComponent {

    @Input() icon: string;
    @Input() name: string;
    @Input() tooltip: string;

}
