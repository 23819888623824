import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

    constructor(
        private translate: TranslateService,
    ) {
    }

    transform(value: number): string {
        if (!value) { return value === 0 ? value.toString() : ''; }
        const newValue = Math.round(value).toString();
        const numberValue = Number(newValue);
        const formattedValue = numberValue.toLocaleString(this.translate.getDefaultLang());
        return newValue !== '' && !isNaN(numberValue) ? formattedValue : value.toString();
    }

}
