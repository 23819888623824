<div class="account-button" (mouseenter)="open()" (mouseleave)="close()">
    <i (click)="navigateToLogin()" [ngClass]="{'active': isAuthenticated(), 'hover': isOpen}" class="icon">user</i>
    <div class="account-button__dropdown" [ngClass]="{'account-button__dropdown--open': isOpen}">
        <ng-container *ngIf="isAuthenticated()">
            <div class="account-username">{{ getUserName() }}</div>
        </ng-container>
        <div class="account-info" *ngIf="isAuthenticatedAsDealer()">
            <ng-container *ngIf="currentPageRouteHasDealerRole()">
                <a class="account-info__link" [routerLink]="['/step/1']">
                    <i class="icon">arrowright</i>
                    {{ 'dealer:menu:configurator:label' | translate }}
                </a>
            </ng-container>
            <ng-container *ngIf="!currentPageRouteHasDealerRole()">
                <a class="account-info__link" [routerLink]="['/dealer/offers']">
                    <i class="icon">arrowright</i>
                    {{ 'dealer:menu:portal:label' | translate }}
                </a>
            </ng-container>
        </div>
        <ng-container *ngIf="!isAuthenticated()">
            <button class="btn btn-outline btn-block" (click)="navigateToLogin()">{{ 'configurator:account:button:login' | translate }} <i class="icon">arrowright</i></button>
        </ng-container>
        <ng-container *ngIf="isAuthenticated()">
            <button class="btn btn-outline btn-block" (click)="logout()">{{ 'configurator:account:button:logout' | translate }} <i class="icon">arrowright</i></button>
        </ng-container>
    </div>
</div>
