import {Injectable} from '@angular/core';
import {IColor, IDefaultColors} from '../../interfaces/information';
import {ApiService} from '../api-service/api-service.service';
import {IOrderConfigurationData} from '../../interfaces/order';

/**
 * Class for updating and retrieving info about the domain and dealer
 */
@Injectable({
    providedIn: 'root',
})
export class ColorService {

    private colors: IColor[];

    constructor(
        private apiService: ApiService,
    ) {
    }

    public getColorForRal(ral: string): IColor {
        // Fix wrong gutter color name "RAL 7016" -> "RAL7016"
        ral = ral.replace(/\s/g, '');

        return this.colors.find((color) => color.ral === ral);
    }

    public migrateConfigurationHexColors(configuration: IOrderConfigurationData): IOrderConfigurationData {
        try {
            // Building info + added data
            ['buildingInfo', 'addedData'].forEach((configurationKey) => {
                const configurationProperty = configuration[configurationKey];
                if (configurationProperty.hasOwnProperty('colours')) {
                    const colorPieces = configurationProperty.colours;
                    Object.keys(colorPieces).forEach((piece) => {
                        const color: IColor = colorPieces[piece];
                        colorPieces[piece] = this.getColorForRal(color.ral);
                    });
                }
            });

            // Template
            Object.keys(configuration.template)
                .forEach((templateKey) => {
                    const templateProperty = configuration.template[templateKey];

                    if (templateProperty.hasOwnProperty('color') && templateProperty.hasOwnProperty('colorName')) {
                        templateProperty.color = this.getColorForRal(templateProperty.colorName).hex;
                        templateProperty.colorName = this.getColorForRal(templateProperty.colorName).ral;
                    }
                });
        } catch (e) {
            console.error('Migrating RAL hex colors failed', e);
        }

        return configuration;
    }

    public getColors(): IColor[] {
        return this.colors;
    }

    public getDefaultColors(): IDefaultColors {
        return {
            base: this.getColorForRal('RAL9006'),
            roof: this.getColorForRal('RAL7016'),
            edge: this.getColorForRal('RAL3016'),
        };
    }

    /**
     * Get the colors from the server
     */
    public resolveColors(): Promise<void> {
        return this.apiService.get(`/colors`)
            .then((response) => {
                this.colors = response.body.map(({hex, ral}) => {
                    return {hex, ral};
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }
}
