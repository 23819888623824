<div
    class="wrapper"
    [ngClass]="{'drag-over': dragOver}"
    (dragleave)="imageDragLeave($event)"
    (dragover)="imageDragOver($event)"
    (drop)="imageDropped($event)"
    [ngStyle]="{'width': previewWidth ? (previewWidth + 2) + 'px' : null, 'height': previewHeight ? (previewHeight + 2) + 'px' : null}"
>
    <input type="file" accept="image/*" (change)="inputChanged($event)" #imageSelector>
    <div *ngIf="!image || image === ''" class="inner add" (click)="openSelectImageDialog()">
        <i class="icon">image</i>
        <div>{{ 'dealer:settings:form:button:addimage' | translate }}</div>
    </div>
    <div *ngIf="image && image !== ''" class="inner preview" [ngClass]="{'cropped': cropThumb}" [ngStyle]="{'background-image': 'url('+image+')'}">
        <button class="btn btn-danger btn-small btn-icon" (click)="removeImage()"><i class="icon">trash</i></button>
    </div>
</div>
