import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidation} from '../../classes';
import {FormUtil} from '../../utils';
import {NotificationService} from '../../services/notification-service';
import {ApiService} from '../../services/api-service/api-service.service';
import {InfoService} from '../../services/info-service/info-service.service';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from 'ngx-webstorage';

@Component({
    selector: 'app-page-dealer-settings-component',
    templateUrl: './page-dealer-settings.component.html',
    styleUrls: ['./page-dealer-settings.component.scss']
})
export class PageDealerSettingsComponent extends FormValidation implements OnInit {

    @LocalStorage('dealerInfo') private readonly dealerInfo;

    public dealerSettingsForm: UntypedFormGroup;
    public affiliateLink: string;
    public dealerSettingsFormHasError = false;
    public dealerSettingsFormSubmitting = false;
    public dealerSettingsFormIsUpdating = false;

    constructor(
        private notificationService: NotificationService,
        private apiService: ApiService,
        private translateService: TranslateService,
        private infoService: InfoService,
    ) {
        super();
    }

    ngOnInit() {

        // Create the reactive form
        this.dealerSettingsForm = new UntypedFormGroup({
            logo: new UntypedFormControl(''),
            address: new UntypedFormControl(''),
            email_address: new UntypedFormControl('', {
                validators: [Validators.required, FormUtil.emailValidator],
                updateOn: 'blur'
            }),
            phone: new UntypedFormControl(''),
            margin: new UntypedFormControl(''),
        });

        // Assign the reactive form for validation
        this.assignFormValidation([this.dealerSettingsForm]);

        // Fill the form with the cached data
        this.dealerSettingsForm.patchValue({...this.dealerInfo});

        // Fill the form with the latest data
        this.dealerSettingsForm.disable();
        this.dealerSettingsFormIsUpdating = true;

        const dealerInfoPromise = this.infoService.getDealerInfo().then((dealerInfo) => {
            this.dealerSettingsForm.patchValue({...dealerInfo});
        });
        const dealerInfoPrivatePromise = this.infoService.getDealerPrivateInfo().then((dealerPrivateInfo) => {
            this.dealerSettingsForm.patchValue({...dealerPrivateInfo});
        });
        Promise.all([dealerInfoPromise, dealerInfoPrivatePromise]).finally(() => {
            this.affiliateLink = `${window.location.origin}/affiliate/${this.infoService.getAffiliateId()}`;
            this.dealerSettingsForm.enable();
            this.dealerSettingsFormIsUpdating = false;
        });
    }

    /**
     * Set the form value when the image logo has been generated
     *
     * @param imageSrc Base64-encoded image
     */
    public logoGenerated(imageSrc: string): void {
        this.dealerSettingsForm.patchValue({
            logo: imageSrc,
        });
    }

    /**
     * Post the form to the API endpoint
     */
    public submitDealerSettingsForm(): void {
        this.dealerSettingsFormSubmitting = true;
        this.dealerSettingsForm.disable();

        this.infoService.saveDealerInfo(this.dealerSettingsForm.value).then(() => {
            this.translateService.get('dealer:settings:form:message:success').subscribe((translation: string) => {
                this.notificationService.openSuccess(translation);
            });
        }).catch((error) => {
            const errorCode = error.status || 0;
            this.translateService.get('dealer:settings:form:message:error').subscribe((translation: string) => {
                this.notificationService.openError(errorCode, translation);
            });
        }).finally(() => {
            this.dealerSettingsFormSubmitting = false;
            this.dealerSettingsForm.enable();
        });
    }

    /**
     * Copy the affiliate link to the clipboard
     */
    public copyAffiliateLink(): void {
        try {
            const temporaryInput = document.createElement('input') as HTMLInputElement;
            document.body.appendChild(temporaryInput);
            temporaryInput.value = this.affiliateLink;
            temporaryInput.select();

            document.execCommand('copy');

            document.body.removeChild(temporaryInput);

            this.translateService.get('dealer:settings:form:affiliatelink:copy:success').subscribe((translation: string) => {
                this.notificationService.open({
                    type: 'success',
                    icon: 'clipboard',
                    message: translation,
                    duration: 2000,
                });
            });
        } catch (e) {}
    }

}
