import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-input-colour-buttons',
    templateUrl: './input-colour-buttons.component.html',
    styleUrls: ['./input-colour-buttons.component.scss']
})
export class InputColourButtonsComponent {
    @Input() colours: string[];
    @Input() currentColour: string;
    @Output() valueChanged = new EventEmitter<string>();

    public changedValue(colour) {
        this.valueChanged.emit(colour);
    }

}
