import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-input-button-image-big',
    templateUrl: './input-button-image-big.component.html',
    styleUrls: ['./input-button-image-big.component.scss']
})
export class InputButtonImageBigComponent {
    @Input() imagePath: string;

}
