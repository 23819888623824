import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {OrderService} from '../../services/order-service/order-service.service';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidation} from '../../classes';
import {FormUtil} from '../../utils';

@Component({
    selector: 'app-modal-finish',
    templateUrl: './modal-finish.component.html',
    styleUrls: ['./modal-finish.component.scss']
})
export class ModalFinishComponent extends FormValidation implements OnInit {

    constructor(
        private modalService: ModalService,
        private orderService: OrderService,
        private router: Router,
    ) {
        super();
    }

    public finishForm: UntypedFormGroup;

    public savingForm = false;
    public serverError = false;

    ngOnInit(): void {
        this.finishForm = new UntypedFormGroup({
            name: new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(255)
            ]),
            email: new UntypedFormControl('', {
                validators: [
                    Validators.required,
                    FormUtil.emailValidator,
                    Validators.maxLength(255)
                ],
                updateOn: 'blur'
            }),
            phone: new UntypedFormControl('', {
                validators: [
                    Validators.required,
                    FormUtil.phoneValidator,
                    Validators.minLength(8),
                    Validators.maxLength(255)
                ],
                updateOn: 'blur'
            }),
            construction_site: new UntypedFormControl('', [
                Validators.maxLength(255),
                Validators.minLength(1),
                Validators.required
            ])
        });
        this.assignFormValidation([this.finishForm]);
    }

    public finish() {
        this.savingForm = true;
        const formData = this.finishForm.value;

        this.orderService.createOrder(
            formData.name,
            formData.email,
            formData.phone,
            formData.construction_site,
            formData.createAccount === true).then(() => {
            this.savingForm = false;
            this.router.navigate(['/send']);
            this.modalService.close();

        }).catch((error) => {
            this.savingForm = false;
            this.serverError = true;
            console.error(error);
        });
    }

    public close() {
        this.savingForm = false;
        this.modalService.close();
    }

}
