<div class="wrapper">
    <div class="main">
        <img class="main__logo" src="../../assets/images/logo-wide.png"/>
        <div class="main__text">
            <div class="main__text--big" [innerText]="'page:sunday:header' | translate"></div>
            <div class="main__text--normal" [innerText]="'page:sunday:main' | translate">
            </div>
        </div>
    </div>
</div>
