import {Component, OnInit} from '@angular/core';
import {IOrder, IOrderVersion} from '../../interfaces/order';
import {ActivatedRoute} from '@angular/router';
import {OrderService} from '../../services/order-service/order-service.service';
import {ModalOrderLineComponent} from '../../components/modal-order-line/modal-order-line.component';
import {ModalService} from '../../services/modal-service';
import {IOrderLine} from '../../interfaces/order-line';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtil} from '../../utils';
import {FileUtil} from '../../utils/file.util';
import {OrderStatus} from '../../enums';
import {ModalOrderConfirmComponent} from '../../components/modal-order-confirm/modal-order-confirm.component';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {NotificationService} from '../../services/notification-service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormatIdPipe} from '../../pipes/format-id.pipe';
import {InfoService} from '../../services/info-service/info-service.service';
import {IDealerInformation} from '../../interfaces/information';

@Component({
    selector: 'app-page-dealer-offer-component',
    templateUrl: './page-dealer-offer.component.html',
    styleUrls: ['./page-dealer-offer.component.scss']
})
export class PageDealerOfferComponent implements OnInit {

    public orderId: number;
    public order: IOrder;
    public isReadonly = false;
    public loadingOrder: boolean;
    public updatingOrder: boolean;
    public updateOrderForm: UntypedFormGroup;
    public dealerInfo: IDealerInformation;

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private modalService: ModalService,
        private translateService: TranslateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private notificationService: NotificationService,
        private infoService: InfoService,
    ) {
    }

    ngOnInit() {
        this.orderId = parseInt(this.route.snapshot.paramMap.get('offer'), 10);
        this.updatingOrder = false;

        this.loadingOrder = true;
        this.orderService.getDetailedOrder(this.orderId).then((order: IOrder) => {
            this.refreshOrder(order);
            this.updateOrderForm.value.comments = order.comments;
            this.loadingOrder = false;
        });

        // Create the reactive form
        this.updateOrderForm = new UntypedFormGroup({
            comments: new UntypedFormControl(''),
        });

        this.infoService.getDealerInfo().then((dealerInfo => {
            this.dealerInfo = dealerInfo;
        }));
    }

    private refreshOrder(order: IOrder) {
        this.order = order;
        this.isReadonly = OrderUtil.isReadOnly(this.order.status);
        this.updateOrderForm.patchValue({
            comments: this.order.comments,
        });
    }

    public createVersion(): void {
        this.orderService.createOrderVersion(this.order.id, {
            configuration: this.order.versions[ this.order.versions.length - 1 ].configuration,
        }).then((order: IOrder) => {
            this.refreshOrder(order);
        });
    }

    public updateCurrentVersion(): void {
        this.updatingOrder = true;
        this.orderService.updateOrder(this.order.id, {
            dealer_order_lines: this.order.dealer_order_lines,
            comments: this.updateOrderForm.value.comments,
        }).then((order: IOrder) => {
            this.refreshOrder(order);
            this.updatingOrder = false;
        });
    }

    public downloadOrder(version: IOrderVersion, order: IOrder): void {
        const formatIdPipe = new FormatIdPipe();
        const filename = 'offerte-'
            + this.dealerInfo.locale + '-'
            + formatIdPipe.transform(order.id )
            + '(versie-' + version.version + ').docx';
        this.loadingSpinnerService.show();
        this.orderService.getDownloadOrder(version.uuid)
            .then((body) => {
                FileUtil.saveFile(body, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', filename);
            })
            .catch((response) => {
                this.translateService.get('dealer:offer:order:download:error').subscribe((translation: string) => {
                    this.notificationService.openError(response.status, translation);
                });
            })
            .finally(() => {
                this.loadingSpinnerService.hide();
            });
    }

    public cancelOrder(): void {
        this.orderService.updateOrder(this.order.id, {
            status: OrderStatus.CANCELED
        }).then((order: IOrder) => {
            this.refreshOrder(order);
        });
    }

    public confirmOrder(): void {
        this.confirmOrderModal(this.order);
    }

    private confirmOrderModal(order: IOrder): void {
        this.modalService.open({
            component: ModalOrderConfirmComponent,
            closeable: true,
            data: {
                parent: this,
            },
        });
    }

    public placeOrder(): void {
        this.loadingOrder = true;
        this.orderService.updateOrder(this.order.id, {
            status: OrderStatus.ORDERED
        }).then((order: IOrder) => {
            this.refreshOrder(order);
            this.loadingOrder = false;
        });
    }

    public addOrderLineModal(): void {
        const newOrderLine = {
            id: undefined,
            title: '',
            price: 0,
        };
        this.openOrderLineModal(newOrderLine);
    }

    private openOrderLineModal(orderLine: IOrderLine): void {
        this.translateService.get(`dealer:offer:order:line:${orderLine.id ? 'edit' : 'add'}:title`).subscribe((translation: string) => {
            this.modalService.open({
                title: translation,
                component: ModalOrderLineComponent,
                closeable: true,
                data: {
                    orderLine,
                    parent: this,
                },
            });
        });
    }

    public addAndSaveOrderLine(orderLine: IOrderLine): void {
        this.order.dealer_order_lines.push(orderLine);
        this.updateCurrentVersion();
    }

    public saveOrderLine(changedOrderLine: IOrderLine): void {
        const orderLineIndex = this.order.dealer_order_lines.findIndex((orderLine) => {
            return orderLine.id === changedOrderLine.id;
        });
        if (orderLineIndex > -1) {
            this.order.dealer_order_lines[orderLineIndex] = changedOrderLine;
            this.updateCurrentVersion();
        }
    }

    public deleteOrderLine(removeOrderLine: IOrderLine): void {
        this.order.dealer_order_lines = this.order.dealer_order_lines.filter((orderLine) => {
            return orderLine !== removeOrderLine;
        });
        this.updateCurrentVersion();
    }
}
