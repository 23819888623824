<app-sidebar-content>
    <app-sidebar-property-component icon="door" tooltip="configurator:step5:tooltip:doors">
        <app-input-button-image-big class="large-vertical-icon" (click)="addDoor(0)" [class.active]="door === 0" [imagePath]="'assets/images/loopdeur.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="large-vertical-icon" (click)="addDoor(1)" [class.active]="door === 1" [imagePath]="'assets/images/loopdeur-variant-2.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="large-vertical-icon" (click)="addDoor(4)" [class.active]="door === 2" [imagePath]="'assets/images/draaideur.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="large-vertical-icon" (click)="addDoor(2)" [class.active]="door === 3" [imagePath]="'assets/images/schuifdeur-met-loopdeur.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="large-vertical-icon" (click)="addDoor(5)" [class.active]="door === 4" [imagePath]="'assets/images/overheaddeur.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="window" tooltip="configurator:step5:tooltip:windows">
        <app-input-button-image-big class="large-vertical-icon single-child" (click)="addWindow(0)" [class.active]="window === 0" [imagePath]="'assets/images/raam1.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="grid" tooltip="configurator:step5:tooltip:transparentsheets">
        <app-input-button-image-big class="vertical-icon" (click)="setWallLightPanels(false)" [class.active]="roofLightPanels === 0 && wallLightPanels === false" [imagePath]="'assets/images/geen-lichtplaten.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="setWallLightPanels(true)" [class.active]="wallLightPanels === true" [imagePath]="'assets/images/lichtplaten-zijwand.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="changeTransparentSheet(3)" [class.active]="roofLightPanels === 3" [imagePath]="'assets/images/1-lichtplaat-per-vak.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="changeTransparentSheet(1)" [class.active]="roofLightPanels === 1" [imagePath]="'assets/images/1-doorgaande-lichtband.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="changeTransparentSheet(4)" [class.active]="roofLightPanels === 4" [imagePath]="'assets/images/2-lichtplaten-per-vak.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="changeTransparentSheet(2)" [class.active]="roofLightPanels === 2" [imagePath]="'assets/images/2-doorgaande-lichtbanden.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>

    <app-sidebar-property-component *ngIf="canHaveGutters()" icon="gutter" tooltip="configurator:step5:tooltip:gutters">
        <app-input-button-image-big class="vertical-icon" (click)="setGutters(false)" [class.active]="!gutterValue" [imagePath]="'assets/images/geen-goten.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="setGutters(true)" [class.active]="gutterValue" [imagePath]="'assets/images/goten.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>
</app-sidebar-content>
