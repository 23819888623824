import {Injectable} from '@angular/core';

/**
 * Class for managing the snapshots amounts that are sent towards the configurator
 */
@Injectable()
export class StatePersistorService {

    private initialState: any = null;
    private persistorKey = 'CONFIGURATION';

    constructor() {
        const initialState = this.retrieveState();
        if (initialState !== null) {
            this.setInitialState(initialState);
        }
    }

    /**
     * Set the state used when we are doing a full reset
     */
    public setInitialState(jsonObject: any) {
        this.initialState = jsonObject;
    }

    /**
     * Check if we have a reset state to return to
     */
    public hasInitialState(): boolean {
        return this.initialState !== null;
    }

    /**
     * Get the reset state to revert to
     */
    public getInitialState(): any {
        return this.initialState;
    }

    /**
     * Persist the state
     */
    public persistState(jsonObject: any): void {
        if (!this.hasInitialState()) {
            this.setInitialState(jsonObject);
        }

        try {
            window.localStorage.setItem(this.persistorKey, JSON.stringify(jsonObject));
        } catch (e) {
            console.error(e);
        }
    }

    public removeStateFromStorage(): void {
        try {
            window.localStorage.removeItem(this.persistorKey);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Retrieve the state
     */
    public retrieveState(): any {
        try {
            return JSON.parse(window.localStorage.getItem(this.persistorKey));
        } catch (e) {
            console.error(e);
            return null;
        }
    }
}
