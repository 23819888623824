import {Injectable} from '@angular/core';
import {ApiService} from '../api-service/api-service.service';
import {
    IOrder,
    IUpdateOrder,
    OrderStatuses,
    IOrderConfiguration,
    IOrderVersion,
    IOrderConfigurationData, IOrderConfigurationDataApi
} from '../../interfaces/order';
import {AuthenticationService} from '../authentication-service';
import {AuthRoles, OrderStatus} from '../../enums';
import {OrderUtil} from '../../utils';
import {StatePersistorService} from '../state-persistor/state-persistor.service';

/**
 * Class for creating and retrieving orders from the server
 */
@Injectable()
export class OrderConfigurationDataMapper {

    constructor(
    ) {
    }

    /**
     * Converts an object retrieved via the API to a usable object.
     * The Backend doesn't care about addedData nor template, so it is stored server side as a string. Deserialize it here
     */
    public fromGet(orderConfigurationData: IOrderConfigurationDataApi): IOrderConfigurationData {
        return {
            addedData: JSON.parse(orderConfigurationData.addedData),
            buildingInfo: orderConfigurationData.buildingInfo,
            template: JSON.parse(orderConfigurationData.template)
        };
    }

    /**
     * Converts an object towards the API.
     * The Backend doesn't care about addedData nor template, so it is stored server side as a string. JSON.stringify it here
     */
    public forPost(orderConfigurationDataApi: IOrderConfigurationData): IOrderConfigurationDataApi {
        return {
            addedData: JSON.stringify(orderConfigurationDataApi.addedData),
            buildingInfo: orderConfigurationDataApi.buildingInfo,
            template: JSON.stringify(orderConfigurationDataApi.template)
        };
    }

}
