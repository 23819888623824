import {Pipe, PipeTransform} from '@angular/core';
import {FormUtil} from '../utils';

@Pipe({
    name: 'convertEurocentToEuro'
})
export class ConvertEurocentToEuroPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number): number {
        return FormUtil.convertEurocentsToEuro(value);
    }

}
