import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-navigation-component',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    public currentStep: number;
    public prevStep: number;
    public nextStep: number;

    public orderUUID: string;
    public dealerIsEditing = false;

    constructor(
        private route: ActivatedRoute,
    ) {}

    @Output() finish: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    set step(currentStep: number) {
        this.currentStep = currentStep;
        this.prevStep = this.currentStep - 1;
        this.nextStep = Math.min(6, this.currentStep + 1);
    }

    ngOnInit() {
        // When a dealer edits the configuration
        this.orderUUID = this.route.snapshot.paramMap.get('uuid') || '';
        this.dealerIsEditing = this.orderUUID !== '';
    }

    public getRouterLinkForStep(step: number) {
        if (this.dealerIsEditing) {
            return ['/dealer/offer', this.orderUUID, 'step', step];
        } else {
            return ['/step', step];
        }
    }

    public onFinish(): void {
        this.finish.emit({});
    }

}
