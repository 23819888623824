import {Injectable} from '@angular/core';
import {ApiService} from './api-service/api-service.service';
import {IAuthInformation} from '../interfaces/authentication';
import * as JWT from 'jwt-decode';
import {LocalStorage} from 'ngx-webstorage';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class AuthenticationService {

    @LocalStorage('jwtAuthenticationToken', '') private jwtAuthenticationToken: string;

    private authenticated: boolean;
    private authInfo: IAuthInformation;
    private authenticationChange = new Subject<boolean>();

    constructor(
        private apiService: ApiService,
    ) {
        this.parseJwtAuthenticationToken();
    }

    private parseJwtAuthenticationToken() {
        let authenticated = false;
        if (!this.expiredJwtAuthenticationToken()) {
            this.authInfo = JWT(this.jwtAuthenticationToken);
            authenticated = true;
        } else {
            this.jwtAuthenticationToken = '';
            this.authInfo = null;
        }
        this.authenticated = authenticated;
        this.authenticationChange.next(this.authenticated);
    }

    private expiredJwtAuthenticationToken() {
        let expiredToken = true;
        if (this.jwtAuthenticationToken !== '') {
            const jwtPayload: IAuthInformation = JWT(this.jwtAuthenticationToken);
            expiredToken = Date.now() >= jwtPayload.exp * 1000;
        }
        return expiredToken;
    }

    public onChanged(): Subject<boolean> {
        return this.authenticationChange;
    }

    public getAuthInfo() {
        return this.authInfo;
    }

    public isAuthenticated() {
        return this.authenticated;
    }

    public login(username: string, password: string): Promise<void> {
        this.jwtAuthenticationToken = '';
        return new Promise((resolve, reject) => {
            this.apiService.post('/login_check', { username: username, password: password })
                .then((response) => {
                    this.jwtAuthenticationToken = response.body.token;
                    this.parseJwtAuthenticationToken();
                    resolve();
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    public logout(): void {
        this.jwtAuthenticationToken = '';
        this.parseJwtAuthenticationToken();
    }

    public userHasRole(roles: string[]): boolean {
        let hasRole = false;
        if (this.authenticated) {
            for (const role of roles) {
                if (!hasRole) {
                    const roleIndex = this.authInfo.roles.indexOf(role);
                    hasRole = roleIndex >= 0;
                }
            }
        }
        return hasRole;
    }
}
