import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

/**
 * Class for sending api requests to the server
 */
@Injectable()
export class ApiService {

    private baseUrl: string;
    private _customHeaders: HttpHeaders;

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiUrl;
        this._customHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    }

    /**
     * Return the options for the http calls
     */
    private getOptions(responseType: string): any {
        return {
            observe: 'response',
            headers: this._customHeaders,
            responseType: responseType
        };
    }

    /**
     * Send a get request to the api server
     */
    public get(url: string, responseType: string = 'json'): Promise<any> {
        return this.httpClient.get(this.baseUrl + url, this.getOptions(responseType)).toPromise();
    }

    /**
     * Send a post request to the api server
     */
    public post(url: string, body: any, responseType: string = 'json'): Promise<any> {
        return this.httpClient.post(this.baseUrl + url, body, this.getOptions(responseType)).toPromise();
    }

    /**
     * Send a put request to the api server
     */
    public put(url: string, body: any, responseType: string = 'json'): Promise<any> {
        return this.httpClient.put(this.baseUrl + url, body, this.getOptions(responseType)).toPromise();
    }

}
