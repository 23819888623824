import {saveAs} from 'file-saver';

export class FileUtil {

    public static saveFile(body: string, fileType: string, fileName: string): void {
        const byteCharacters = atob(body);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: fileType});
        saveAs(blob, fileName);
    }
}
