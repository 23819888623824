import {ActivatedRouteSnapshot} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

export class RouteUtil {

    public static getRouteData(activeRoute: ActivatedRouteSnapshot, dataProperty: string, defaultValue: any): any {
        const hasDataProperty = activeRoute && activeRoute.data && typeof activeRoute.data[dataProperty] === typeof defaultValue;
        return hasDataProperty ? activeRoute.data[dataProperty] : defaultValue;
    }

    public static getAuthRedirectUrl(activeRoute: ActivatedRouteSnapshot) {
        return activeRoute.queryParams.redirectUrl || this.getRouteData(activeRoute, 'authRedirect', '/');
    }

    public static getErrorCode(response: HttpErrorResponse): number {
        let errorCode = 0;
        if (response && response.status) {
            errorCode = response.status;
        }
        return errorCode;
    }

}
