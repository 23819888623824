import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private defaultConfig = {
        anonymize_ip: true,
        allow_ad_personalization_signals: false,
    };

    constructor(
        public router: Router,
    ) {
    }

    public init() {
        this.appendScript();
        this.listenForRouteChanges();
    }

    private appendScript() {
        const script = document.createElement('script') as HTMLScriptElement;
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsID}`;
        document.querySelector('body').appendChild(script);

        this.gtag('js', new Date());
        this.gtag('config', `${environment.googleAnalyticsID}`, this.getConfig({
            send_page_view: false,
        }));
    }

    public gtag(key: string, prop: any, data?: any) {
        const win = (window as any);
        win.dataLayer = win.dataLayer || [];
        win.dataLayer.push(arguments);
    }

    private getConfig(config: object): object {
        return Object.assign({}, this.defaultConfig, config);
    }

    private listenForRouteChanges() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.gtag('config', `${environment.googleAnalyticsID}`, this.getConfig({
                    page_path: `${event.url}`
                }));
            }
        });
    }

}
