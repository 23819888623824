<app-sidebar-content *ngIf="coating">
    <app-sidebar-property-component icon="uprofile" tooltip="configurator:step3:tooltip:beams">
        <app-input-button-image-big (click)="coatingChanged('purlins', 'wood')" [class.active]="coating.purlins === 'wood'" [imagePath]="'assets/images/hout.svg'"></app-input-button-image-big>
        <app-input-button-image-big (click)="coatingChanged('purlins', 'metal')" [class.active]="coating.purlins === 'metal'" [imagePath]="'assets/images/staal.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="sheets" tooltip="configurator:step4:tooltip:double">
        <app-input-button-image-big class="vertical-icon" (click)="coatingChanged('wallType', 'configurator:step4:choice:single')" [class.active]="coating.wallType === 'configurator:step4:choice:single'" [imagePath]="'assets/images/enkelwandig.svg'"></app-input-button-image-big>
        <app-input-button-image-big class="vertical-icon" (click)="coatingChanged('wallType', 'configurator:step4:choice:double')" [class.active]="coating.wallType === 'configurator:step4:choice:double'" [imagePath]="'assets/images/dubbelwandig.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>

    <ng-container *ngIf="coating.wallType === 'configurator:step4:choice:single'">
        <app-sidebar-property-component icon="waterdrops" tooltip="configurator:step4:tooltip:condensation">
            <app-input-button-image-big class="vertical-icon" (click)="coatingChanged('antiCondensationFilter', 'configurator:choice:no')" [class.active]="!coating.antiCondensationFilter" [imagePath]="'assets/images/geen-anticondens-2.svg'"></app-input-button-image-big>
            <app-input-button-image-big class="vertical-icon" (click)="coatingChanged('antiCondensationFilter', 'configurator:choice:yes')" [class.active]="coating.antiCondensationFilter" [imagePath]="'assets/images/anti-condens.svg'"></app-input-button-image-big>
        </app-sidebar-property-component>
    </ng-container>

    <ng-container *ngIf="coating.wallType === 'configurator:step4:choice:double'">
        <app-sidebar-property-component icon="barnroof" tooltip="configurator:step4:tooltip:insulation">
            <app-input-slider [steps]="coating.roofIsolation.steps" [value]="coating.roofIsolation.value" (valueChanged)="coatingChanged('roofIsolation', $event)" unit="mm"></app-input-slider>
        </app-sidebar-property-component>
    </ng-container>

    <ng-container *ngIf="coating.wallType === 'configurator:step4:choice:double'">
        <app-sidebar-property-component icon="barnwallandfacade" tooltip="configurator:step4:tooltip:insulation">
            <app-input-slider [steps]="coating.wallIsolation.steps" [value]="coating.wallIsolation.value" (valueChanged)="coatingChanged('wallIsolation', $event)" unit="mm"></app-input-slider>
        </app-sidebar-property-component>
    </ng-container>
</app-sidebar-content>
