import {Injectable} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {IModalData} from '../interfaces/modal';
import {ModalModel} from '../model/modal-model';

@Injectable()
export class ModalService {

    private modalId = 'globalModal';

    constructor(
        private ngxSmartModalService: NgxSmartModalService,
    ) {
    }

    public open(data: IModalData) {
        const modalData = new ModalModel(data);
        this.ngxSmartModalService.resetModalData(this.modalId);
        this.ngxSmartModalService.setModalData(modalData, this.modalId);

        const modal = this.ngxSmartModalService.getModal(this.modalId);
        modal.customClass = `global-modal ${modalData.customClass}`;
        modal.open();
    }

    public getData(): ModalModel {
        return this.ngxSmartModalService.getModalData(this.modalId);
    }

    public close() {
        this.ngxSmartModalService.getModal(this.modalId).close();
        this.ngxSmartModalService.resetModalData(this.modalId);
    }

}
