import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent {

    @Input() public name: string;
    @Input() public label: string;
    @Input() public checked: boolean;
    @Input() imagePath: string;
    @Output() valueChanged = new EventEmitter<boolean>();

    constructor() {
    }

    public toggleVisibility(event) {
        this.checked = event.target.checked;
        this.valueChanged.emit(this.checked);
    }
}
