<app-header-component>
    <ng-container *ngIf="dealerInfo" account>
        <div class="header-contact" *ngIf="dealerInfo.email_address !== ''"><a href="mailto:{{ dealerInfo.email_address }}"><i class="icon">email</i> {{ dealerInfo.email_address }}</a></div>
        <div class="header-contact" *ngIf="dealerInfo.phone !== ''"><i class="icon">mobile</i> {{ dealerInfo.phone }}</div>
    </ng-container>
</app-header-component>
<div class="offer-bar"></div>
<main>
    <div class="offer-message">
        <h1 class="offer-message__title">{{ 'configurator:offer:send:message:title' | translate }}</h1>
        <div class="offer-message__remarks">{{ 'configurator:offer:send:message:remarks' | translate }}</div>
        <div class="offer-message__contact"><a href="mailto:{{ offerEmail }}"><i class="icon">email</i> {{ offerEmail }}</a></div>
    </div>
    <div class="offer-dealer">
        <div class="offer-dealer__title" *ngIf="dealerInfo.name === ''">>{{ 'configurator:offer:dealer:title' | translate }}</div>
        <div class="offer-dealer__title" *ngIf="dealerInfo.name !== ''">{{ dealerInfo.name }}</div>
        <div class="offer-dealer__remarks" *ngIf="dealerInfo">
            <div class="offer-dealer__address" *ngIf="dealerInfo.address !== ''">{{ dealerInfo.address }}</div>
            <div class="offer-dealer__email" *ngIf="dealerInfo.email_address !== ''"><a href="mailto:{{ dealerInfo.email_address }}"><i class="icon">email</i> {{ dealerInfo.email_address }}</a></div>
        </div>
    </div>
    <div class="offer-account">
        <h2 class="offer-account__title">{{ 'configurator:offer:account:title' | translate }}</h2>
        <div class="offer-account__remarks">{{ 'configurator:offer:account:remarks' | translate }}</div>
        <div class="offer-account__buttons">
            <button class="btn btn-primary btn-block btn-large" [routerLink]="['/offer', offerUUID]">{{ 'configurator:offer:button:view' | translate }}</button>
            <button class="btn btn-outline btn-block btn-large" [routerLink]="['/step/1']">{{ 'configurator:offer:button:new' | translate }}</button>
        </div>
    </div>
</main>
