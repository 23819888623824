import {AfterContentChecked, Component, ElementRef, HostListener, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-sidebar-content',
    templateUrl: './sidebar-content.component.html',
    styleUrls: ['./sidebar-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarContentComponent implements AfterContentChecked {

    public showMoreContentTrigger: boolean = false;
    private scrollEndThreshold: number = 80;

    constructor(
        private hostRef: ElementRef,
    ) {
    }

    ngAfterContentChecked() {
        this.calculateContentOverflow();
    }

    @HostListener('scroll')
    private calculateContentOverflow(): void {
        const hostEl = this.hostRef.nativeElement;

        this.showMoreContentTrigger = hostEl.offsetHeight - (hostEl.scrollHeight - hostEl.scrollTop - this.scrollEndThreshold) < 0;
    }

}
