<app-header-component>
    <ng-container content>
        <div class="go-back"><a (click)="goBack()"><i class="icon">chevronleft</i></a></div>
    </ng-container>
    <ng-container account>
        <ng-container *ngIf="dealerInfo">
            <div class="header-contact" *ngIf="dealerInfo.email_address !== ''"><a href="mailto:{{ dealerInfo.email_address }}"><i class="icon">email</i> {{ dealerInfo.email_address }}</a></div>
            <div class="header-contact" *ngIf="dealerInfo.phone !== ''"><i class="icon">mobile</i> {{ dealerInfo.phone }}</div>
        </ng-container>
        <app-account-button></app-account-button>
    </ng-container>
</app-header-component>
<main>
    <section>
        <app-viewpoint></app-viewpoint>
        <app-three-d-viewer-component></app-three-d-viewer-component>
    </section>
    <aside *ngIf="order">
        <div class="offer-date">
            <div class="date">
                {{ order.created_at | date:'dd-MM-yyyy' }}
            </div>
            <div *ngIf="blueprintRequestAllowed" class="blueprints-button" (click)="requestBlueprint()"
                    [attr.data-balloon-length]="'medium'" [attr.data-balloon]="'dealer:offer:order:configuration:request_blueprint:button' | translate" data-balloon-pos="left">
                <i class="icon blueprints"></i>
            </div>
        </div>
        <div class="aside-accordion-wrapper">
            <app-accordion icon="type" [name]="'configurator:step1:title' | translate" [open]="true">
                <app-list [label]="'configurator:step1:title' | translate">{{ ('configurator:step1:type:' + getValue(configurationData, 'buildingInfo.structureType')) | translate }}</app-list>
            </app-accordion>
            <app-accordion icon="dimensions" [name]="'configurator:step2:title' | translate">
                <app-list [label]="'configurator:step2:length' | translate">{{ getValue(configurationData, 'buildingInfo.centerToCenterLengthInMM') | convertMillimeterToMeter }} m</app-list>
                <app-list [label]="'configurator:step2:width' | translate">{{ getValue(configurationData, 'buildingInfo.centerToCenterWidthInMM') | convertMillimeterToMeter }} m</app-list>
                <app-list [label]="'configurator:step2:height' | translate">{{ getValue(configurationData, 'buildingInfo.roofTopHeightInMM') | convertMillimeterToMeter }} m</app-list>
                <app-list *ngIf="getValue(configurationData, 'template.frontOffset', 0) > 0" [label]="'configurator:step2:frontOffset' | translate">{{ getValue(configurationData, 'template.frontOffset', 0) }} m</app-list>
                <app-list *ngIf="getValue(configurationData, 'template.facadeInside', 0) > 0" [label]="'configurator:step2:facadeInside' | translate">{{ getValue(configurationData, 'template.facadeInside', 0) }} m</app-list>
                <app-list *ngIf="getValue(configurationData, 'template.backOffset', 0) > 0" [label]="'configurator:step2:backOffset' | translate">{{ getValue(configurationData, 'template.backOffset', 0) }} m</app-list>
                <app-list *ngIf="getValue(configurationData, 'template.frontCanopy') !== 'template.frontCanopy'" [label]="'configurator:step2:front:canopy' | translate">{{ getValue(configurationData, 'template.frontCanopy', 0) }} m</app-list>
                <app-list *ngIf="getValue(configurationData, 'template.backCanopy') !== 'template.backCanopy'" [label]="'configurator:step2:back:canopy' | translate">{{ getValue(configurationData, 'template.backCanopy', 0) }} m</app-list>
            </app-accordion>
            <app-accordion icon="snow" [name]="'configurator:step3:title' | translate">
                <app-list [label]="'configurator:step3:snow' | translate">{{ getValue(configurationData, 'buildingInfo.snowLoadInKgM2') }} kg/m2</app-list>
                <app-list [label]="'configurator:step3:wind' | translate">{{ getValue(configurationData, 'buildingInfo.windLoadInMS') }} m/s</app-list>
            </app-accordion>
            <app-accordion icon="isolationroof" [name]="'configurator:step4:title' | translate">
                <app-list [label]="'configurator:step4:wall' | translate">{{ getValue(configurationData, 'addedData.coating.wallType') | translate }}</app-list>
                <ng-container *ngIf="getValue(configurationData, 'addedData.coating.wallType') === 'configurator:step4:choice:single'">
                    <app-list [label]="'configurator:step4:condensation:filter' | translate"> {{ ('configurator:step4:condensation:filter:' + (getValue(configurationData, 'addedData.coating.antiCondensationFilter') ? 'yes' : 'no')) | translate }}</app-list>
                </ng-container>
                <ng-container *ngIf="getValue(configurationData, 'addedData.coating.wallType') === 'configurator:step4:choice:double'">
                    <app-list [label]="'configurator:step4:insulation:wall' | translate">{{ getValue(configurationData, 'addedData.coating.wallIsolation.value') }} mm</app-list>
                    <app-list [label]="'configurator:step4:insulation:roof' | translate">{{ getValue(configurationData, 'addedData.coating.roofIsolation.value') }} mm</app-list>
                </ng-container>
                <app-list [label]="'configurator:step4:purlins' | translate">{{ ('configurator:step4:purlins:' + getValue(configurationData, 'addedData.coating.purlins')) | translate }}</app-list>
            </app-accordion>
            <app-accordion icon="door" [name]="'configurator:step5:title' | translate">
                <app-list [label]="'configurator:step5:doors:count:label' | translate">{{ getValue(configurationData, 'template.doors.length', '0') }}</app-list>
                <app-list [label]="'configurator:step5:windows:count:label' | translate">{{ getValue(configurationData, 'template.windows.length', '0') }}</app-list>
            </app-accordion>
            <app-accordion icon="color" [name]="'configurator:step6:title' | translate">
                <app-list [label]="'configurator:step6:color:roof:label' | translate">
                    <app-color [color]="getValue(configurationData, 'template.roofColor.color')">{{ getValue(configurationData, 'template.roofColor.colorName') }}</app-color>
                </app-list>
                <app-list [label]="'configurator:step6:color:wall:label' | translate">
                    <app-color [color]="getValue(configurationData, 'template.westWallColor.color')">{{ getValue(configurationData, 'template.westWallColor.colorName') }}</app-color>
                </app-list>
                <app-list [label]="'configurator:step6:color:facade:label' | translate">
                    <app-color [color]="getValue(configurationData, 'template.facadeFrontColor.color')">{{ getValue(configurationData, 'template.facadeFrontColor.colorName') }}</app-color>
                </app-list>
                <app-list [label]="'configurator:step6:color:edge:label' | translate">
                    <app-color [color]="getValue(configurationData, 'template.edgeColor.color')">{{ getValue(configurationData, 'template.edgeColor.colorName') }}</app-color>
                </app-list>
            </app-accordion>
        </div>
        <div class="dealer-button-wrapper" *ngIf="dealerCanEditOrder">
            <button class="btn btn-block" [routerLink]="['/dealer/offer', orderUUID, 'step', 1]">{{ 'dealer:offer:order:configuration:edit:button' | translate }}</button>
        </div>
    </aside>
</main>
