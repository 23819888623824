import {Component} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {SnapshotManagerService} from '../../services/snapshot-manager/snapshot-manager.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-modal-reset-button',
    templateUrl: './modal-reset-button.component.html',
    styleUrls: ['./modal-reset-button.component.scss']
})
export class ModalResetButtonComponent {

    constructor(
        private modalService: ModalService,
        private snapshotManager: SnapshotManagerService,
        private router: Router,
    ) {
    }

    public reset(): void {
        this.router.navigateByUrl('/step/1').then(() => {
            this.snapshotManager.reloadWithDefaults();
            this.close();
        });
    }

    public close(): void {
        this.modalService.close();
    }

}
