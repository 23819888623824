<main>
    <form [formGroup]="resetForm" [ngClass]="{'form-submit-error': resetFormHasError}" class="login-form">
        <div class="login-form__logo"><img *ngIf="dealerInfo" [src]="dealerInfo.logo && dealerInfo.logo !== '' ? dealerInfo.logo : 'assets/images/logo-wide.png'"></div>

        <h2 class="align-center">{{ 'configurator:form:reset:title' | translate }}</h2>
        <p *ngIf="!resetSuccessful" class="margin-text"><small>{{ 'configurator:form:reset:explanation' | translate }}</small></p>

        <ng-container *ngIf="resetSuccessful">
            <p class="margin-text">{{ 'configurator:form:reset:sent' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="!resetSuccessful">
            <div class="form-group">
                <div class="input-group no-label">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="icon">user</i></div>
                    </div>
                    <input type="text" class="form-control" [placeholder]="'configurator:form:login:username:placeholder' | translate" [ngClass]="{'is-invalid': resetFormHasError || !isFormFieldValid('emailAddress')}" formControlName="emailAddress">
                </div>
                <div class="invalid-feedback-block" *ngIf="!isFormFieldValid('emailAddress') && getFormFieldError('emailAddress') === 'required'">{{ 'configurator:form:field:required' | translate }}</div>
                <div class="invalid-feedback-block" *ngIf="!isFormFieldValid('emailAddress') && getFormFieldError('emailAddress') === 'invalidEmail'">{{ 'configurator:form:field:invalid:email' | translate }}</div>
            </div>

            <div class="login-form__buttons">
                <button [attr.disabled]="resetFormSubmission ? 'disabled' : null" class="btn btn-block primary" (click)="reset()">
                    <div *ngIf="resetFormSubmission" class="loading-spinner-inline"></div>
                    {{ 'configurator:form:reset:button:submit' | translate }}
                </button>
            </div>
        </ng-container>
    </form>
</main>
