import {IModalData} from '../interfaces/modal';

export class ModalModel {
    public title: string;
    public template: string;
    public closeable: boolean;
    public component: any;
    public data: any;
    public customClass: string;

    constructor(data: IModalData) {
        this.title = data.title || '';
        this.template = data.template || '';
        this.closeable = data.closeable || false;
        this.component = data.component || false;
        this.data = data.data || {};
        this.customClass = data.customClass || '';
    }
}
