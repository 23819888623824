import {Component} from '@angular/core';
import {IDealerInformation} from '../../interfaces/information';
import {LocalStorage} from 'ngx-webstorage';

@Component({
    selector: 'app-header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    @LocalStorage() public readonly dealerInfo: IDealerInformation;

    constructor() {
    }
}
