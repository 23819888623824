import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-page-sunday-component',
    templateUrl: './page-sunday.component.html',
    styleUrls: ['./page-sunday.component.scss']
})
export class PageSundayComponent {

    constructor() {
    }
}
