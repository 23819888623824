import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, UrlTree, Router} from '@angular/router';
import {InfoService} from '../services/info-service/info-service.service';

@Injectable({
    providedIn: 'root'
})
export class AffiliateIdGuard implements CanActivate {

    constructor(
        private infoService: InfoService,
        private router: Router,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
        if (route.params.affiliateId && route.params.affiliateId !== '') {
            this.infoService.setAffiliateId(route.params.affiliateId);
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

}
