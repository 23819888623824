<div class="viewpoint">
    <div class="viewpoint__center">
        <svg class="pan-tool" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="60" height="60">
            <path class="pan-tool__item" [ngClass]="{'pan-tool__item--hover': viewpoint === 'left' || viewpointHover === 'left'}" (mouseenter)="hoverViewpoint('left')" (mouseleave)="hoverViewpoint('none')" (click)="changeViewpoint('left')" d="M17,39V21a4,4,0,0,1,.56-2L9.51,10.93a28,28,0,0,0,0,38.15L17.57,41A3.94,3.94,0,0,1,17,39Z"/>
            <path class="pan-tool__item" [ngClass]="{'pan-tool__item--hover': viewpoint === 'back' || viewpointHover === 'back'}" (mouseenter)="hoverViewpoint('back')" (mouseleave)="hoverViewpoint('none')" (click)="changeViewpoint('back')" d="M21,17H39a4,4,0,0,1,2,.57l8-8a28,28,0,0,0-38.14,0l8,8.05A3.89,3.89,0,0,1,21,17Z"/>
            <path class="pan-tool__item" [ngClass]="{'pan-tool__item--hover': viewpoint === 'right' || viewpointHover === 'right'}" (mouseenter)="hoverViewpoint('right')" (mouseleave)="hoverViewpoint('none')" (click)="changeViewpoint('right')" d="M50.49,10.93,42.43,19A4,4,0,0,1,43,21V39a3.83,3.83,0,0,1-.57,2l8.06,8.06A28,28,0,0,0,50.49,10.93Z"/>
            <path class="pan-tool__item" [ngClass]="{'pan-tool__item--hover': viewpoint === 'front' || viewpointHover === 'front'}" (mouseenter)="hoverViewpoint('front')" (mouseleave)="hoverViewpoint('none')" (click)="changeViewpoint('front')" d="M41,42.43A3.83,3.83,0,0,1,39,43H21a4,4,0,0,1-2-.57l-8,8.06a28,28,0,0,0,38.15,0Z"/>
            <rect class="pan-tool__center" [ngClass]="{'pan-tool__center--hover': viewpointHover === 'presentation' || viewpoint === 'presentation'}" (mouseenter)="hoverViewpoint('presentation')" (mouseleave)="hoverViewpoint('none')" (click)="changeViewpoint('presentation')" x="19" y="19" width="22" height="22" rx="2"/>
        </svg>
    </div>
</div>
