<app-header-component>
    <ng-container account>
        <app-account-button></app-account-button>
    </ng-container>
</app-header-component>
<main class="page-dealer-main">
    <aside class="page-dealer-main__aside">
        <app-dealer-menu></app-dealer-menu>
    </aside>
    <section class="page-dealer-main__section">
        <div class="padded">
            <div class="title-wrapper">
                <div class="button-container" *ngIf="!loadingOrder && !isReadonly">
                    <button class="btn btn-danger" (click)="cancelOrder()">{{ 'dealer:offer:cancel' | translate }}</button>
                    <button class="btn" (click)="confirmOrder()">{{ 'dealer:offer:finish' | translate }}</button>
                </div>
                <h1 class="float-left title">
                    {{ 'dealer:order:title' | translate }}
                    <small class="text-light">/ {{ orderId | formatId }}</small>
                    <ng-container *ngIf="!loadingOrder">
                        <small class="text-light"> / </small>
                        <small [class]="'order-status-' + order.status">{{ 'dealer:order:status:' + order.status | translate }}</small>
                    </ng-container>
                </h1>
            </div>
        </div>

        <div class="padded">
            <app-box>
                <div class="boxed-table-wrapper">
                    <div class="price-label">{{ 'dealer:offers:column:customer' | translate }}</div>
                    <table>
                        <thead>
                            <tr>
                                <th>{{ 'dealer:offers:column:name' | translate }}</th>
                                <th>{{ 'dealer:offers:column:email' | translate }}</th>
                                <th>{{ 'dealer:offers:column:phone' | translate }}</th>
                                <th>{{ 'dealer:offers:column:construction_site' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!loadingOrder && order">
                                <tr>
                                    <td>{{ order.name }}</td>
                                    <td><a href="mailto:{{ order.email_address }}">{{ order.email_address }}</a></td>
                                    <td>{{ order.phone }}</td>
                                    <td>{{ order.construction_site }}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="loadingOrder">
                                <tr>
                                    <td colspan="4"><app-loading-spinner>{{ 'dealer:offer:customer:message:loading' | translate }}</app-loading-spinner></td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loadingOrder && !order">
                                <tr>
                                    <td colspan="4">
                                        <i class="icon">alert</i>
                                        {{ 'dealer:offer:customer:message:error' | translate }}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </app-box>
        </div>

        <div class="padded">
            <app-box>
                <div class="boxed-table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th width="90">{{ 'dealer:offers:column:version' | translate }}</th>
                                <th width="140">{{ 'dealer:offers:column:sales_price' | translate }}</th>
                                <th width="190">{{ 'dealer:offers:column:created' | translate }}</th>
                                <th width="190" class="hide-1500">{{ 'dealer:offers:column:updated' | translate }}</th>
                                <th width="250"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!loadingOrder && order">
                                <tr *ngFor="let version of order.versions">
                                    <td>{{ version.version }}</td>
                                    <td>{{ version.total_dealer_price | formatEuroCents }}</td>
                                    <td class="hide-1500"><small>{{ version.created_at | date:'dd-MM-yyyy' }} {{ 'dealer:order:date:at' | translate }} {{ version.created_at | date:'HH:mm' }}</small></td>
                                    <td><small>{{ version.updated_at | date:'dd-MM-yyyy' }} {{ 'dealer:order:date:at' | translate }} {{ version.updated_at | date:'HH:mm' }}</small></td>
                                    <td>
                                        <a (click)="downloadOrder(version, order)" class="order-line-action-download"><i class="icon">word</i></a>
                                        <a class="link-primary" [routerLink]="['/offer', version.uuid]">{{ 'dealer:offer:open' | translate }}</a>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="loadingOrder">
                                <tr>
                                    <td colspan="5"><app-loading-spinner>{{ 'dealer:offer:versions:message:loading' | translate }}</app-loading-spinner></td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loadingOrder && !order">
                                <tr>
                                    <td colspan="2">
                                        <i class="icon">alert</i>
                                        {{ 'dealer:offer:versions:message:error' | translate }}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </app-box>
        </div>

        <div class="padded">
            <app-box>
                <div class="boxed-table-wrapper">
                    <table [ngClass]="{'border-bottom': !loadingOrder && order}">
                        <thead>
                            <tr>
                                <th>{{ 'dealer:offers:column:configuration' | translate }}</th>
                                <th class="price-col">{{ 'dealer:offers:column:sales_price' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!loadingOrder && order">
                                <tr class="text-highlight">
                                    <td>{{ 'dealer:offer:building' | translate }}</td>
                                    <td>{{ order.total_price | formatEuroCents }}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="loadingOrder">
                                <tr>
                                    <td colspan="3"><app-loading-spinner>{{ 'dealer:offer:configuration:message:loading' | translate }}</app-loading-spinner></td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loadingOrder && !order">
                                <tr>
                                    <td colspan="3">
                                        <i class="icon">alert</i>
                                        {{ 'dealer:offer:configuration:message:error' | translate }}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <ng-container *ngIf="!loadingOrder && order">
                        <table class="border-bottom" *ngIf="this.isReadonly && order.dealer_order_lines.length > 0 || !this.isReadonly">
                            <thead>
                                <tr>
                                    <th>{{ 'dealer:offer:additional_order_lines' | translate }}</th>
                                    <th class="price-col"></th>
                                    <th class="price-col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-highlight" *ngFor="let orderLine of order.dealer_order_lines">
                                    <td>{{ orderLine.title }}</td>
                                    <td></td>
                                    <td>
                                        <div class="order-line-actions" *ngIf="!this.isReadonly">
                                            <a (click)="openOrderLineModal(orderLine)" class="order-line-action-edit"><i class="icon">edit</i></a>
                                            <a (click)="deleteOrderLine(orderLine)" class="order-line-action-delete"><i class="icon">delete</i></a>
                                        </div>
                                        {{ orderLine.price | formatEuroCents }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="!this.isReadonly"><a class="link-add" (click)="addOrderLineModal()"><i class="icon">plus</i> {{ 'dealer:offer:add_order_line' | translate }}</a></div>

                        <div class="price">
                            {{ order.grand_total_price | formatEuroCents }}
                            <app-loading-spinner *ngIf="updatingOrder"></app-loading-spinner>
                        </div>
                        <div class="price-label">{{ 'dealer:offers:column:price' | translate }}</div>
                        <div class="price-description">{{ 'dealer:offer:price_ex_tax' | translate }}</div>
                    </ng-container>
                </div>

                <ng-container *ngIf="!loadingOrder && !isReadonly">
                    <hr>
                    <form [formGroup]="updateOrderForm">
                            <div class="comment-label">
                                {{ 'dealer:offer:form:comments:label' | translate }}
                                <app-loading-spinner *ngIf="updatingOrder"></app-loading-spinner>
                            </div>
                            <div class="comment-area">
                                <textarea name="comments" class="form-control" formControlName="comments"></textarea>
                            </div>
                    </form>
                    <hr>
                    <button class="btn" (click)="updateCurrentVersion()">{{ 'dealer:offer:edit' | translate }}</button>
                    <button class="btn btn-blue" (click)="createVersion()">{{ 'dealer:offer:create_version' | translate }}</button>
                </ng-container>
            </app-box>
        </div>
    </section>
</main>
