import {Component, OnInit, OnDestroy} from '@angular/core';
import {SnapshotManagerService} from '../../services/snapshot-manager/snapshot-manager.service';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-unredo-buttons',
    templateUrl: './unredo-buttons.component.html',
    styleUrls: ['./unredo-buttons.scss']
})
export class UnredoButtonsComponent implements OnInit, OnDestroy {

    private configuratorEventListener: Subscription;

    public canUndo: boolean;
    public canRedo: boolean;

    constructor(
        private snapshotManager: SnapshotManagerService,
        private configuratorInterface: ConfiguratorInterfaceService,
    ) {
    }

    ngOnInit() {
        this.configuratorEventListener = this.configuratorInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'stateChanged' || data.action === 'UndoRedoState') {
                this.determineButtonStates();
            }
        });

        this.determineButtonStates();
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    private determineButtonStates(): void {
        this.canUndo = this.snapshotManager.canUndo();
        this.canRedo = this.snapshotManager.canRedo();
    }

    public undo(): void {
        this.snapshotManager.undo();
    }

    public redo(): void {
        this.snapshotManager.redo();
    }

}
