<div class="accordion">
    <div class="accordion__name" [ngClass]="{'accordion__name--open': open}" (click)="toggleItem()">
        <i class="accordion__chevron icon" [ngClass]="{'accordion__chevron--open': open}">chevrondown</i>
        <i class="accordion__icon icon">{{ icon }}</i>
        {{ name }}
    </div>
    <div class="accordion__content-wrapper" #contentWrapper>
        <div class="accordion__content" [ngClass]="{'accordion__content--open': open}" #content><ng-content></ng-content></div>
    </div>
</div>
