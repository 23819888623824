import {Component} from '@angular/core';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {NotificationService} from '../../services/notification-service';
import {PriceCalculatorService} from '../../services/price-calculator/price-calculator.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {IOrderConfigurationData} from '../../interfaces/order';

const isEqual = require('lodash.isequal');

@Component({
    selector: 'app-price-bar',
    templateUrl: './price-bar.component.html',
    styleUrls: ['./price-bar.scss']
})
export class PriceBarComponent {

    private configuratorEventListener: Subscription;

    public error = false;
    public loadingPrice = false;
    public currentPriceInEuroCents;
    public previousState: IOrderConfigurationData;

    constructor(
        private configuratorInterface: ConfiguratorInterfaceService,
        private priceCalculator: PriceCalculatorService,
        private notificationService: NotificationService
    ) {
        this.priceCalculator.onLoading().subscribe((isLoading: boolean) => {
            this.error = false;
            this.loadingPrice = isLoading;
        });

        this.priceCalculator.onPriceChange().subscribe((serverData: any) => {
            if (serverData !== false) {
                this.currentPriceInEuroCents = serverData.body.priceInCents;
            } else {
                this.error = true;
            }
        });

        this.configuratorEventListener = configuratorInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'stateChanged') {
                const dataSet = Object.assign({}, data);
                delete dataSet.action;

                const orderConfigurationData: IOrderConfigurationData = Object.assign({}, dataSet);
                if (!isEqual(this.previousState, orderConfigurationData)) {
                    this.priceCalculator.calculatePrice(orderConfigurationData);
                }
                this.previousState = orderConfigurationData;
            }
        });
    }

    /**
     * Copy the last used price json so we can use its result in our testing
     */
    public copyLastPriceJson(): void {
        try {
            const temporaryInput = document.createElement('input') as HTMLInputElement;
            document.body.appendChild(temporaryInput);
            temporaryInput.value = JSON.stringify(this.priceCalculator.getLastSentPriceJson());
            temporaryInput.select();

            document.execCommand('copy');

            document.body.removeChild(temporaryInput);

            this.notificationService.openSuccess('Prijsdata gekopieerd!', 1000);
        } catch (e) {
            console.error(e);
        }
    }

}
