<app-sidebar-content *ngIf="load">
    <app-sidebar-property-component icon="snow" tooltip="configurator:step3:tooltip:snow">
        <app-input-slider [steps]="load.snow.steps" [value]="load.snow.value" (valueChanged)="loadChanged('snow', $event)" unit="kg/m2"></app-input-slider>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="wind" tooltip="configurator:step3:tooltip:wind">
        <app-input-slider [steps]="load.wind.steps" [value]="load.wind.value" (valueChanged)="loadChanged('wind', $event)" unit="m/s"></app-input-slider>
    </app-sidebar-property-component>

    <app-sidebar-property-component name="DIN" tooltip="configurator:step3:tooltip:construction" style="display:none"> <!-- Client does not want this "as of now" (his words) so only hidden, until certain.-->
        <app-input-button-toggle [currentValue]="load.din" [values]="['configurator:choice:yes', 'configurator:choice:no']" (valueChanged)="loadChanged('din', $event)"></app-input-button-toggle>
    </app-sidebar-property-component>
</app-sidebar-content>
