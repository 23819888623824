import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {ColorService} from '../../services/color-service/color-service.service';
import {buildingType} from '../../model/building-type';

@Component({
    selector: 'app-step-building-type-component',
    templateUrl: './step-building-type.component.html',
    styleUrls: ['./step-building-type.component.scss']
})
export class StepBuildingTypeComponent implements OnInit, OnDestroy {
    configuratorEventListener: Subscription;
    type: buildingType;

    constructor (
        private configInterface: ConfiguratorInterfaceService,
        private colorService: ColorService,
    ) {
        this.configuratorEventListener = this.configInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'stateChanged') {
                this.type = data.buildingInfo.structureType;
            }
        });
    }

    ngOnInit() {
        this.configInterface.viewerReady().then(() => {
            this.configInterface.requestState();
        });
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    createBuilding(type: buildingType) {
        const defaultColors = this.colorService.getDefaultColors();

        this.configInterface.sendMessage({
            action: 'createDemoBuilding',
            params: [type, defaultColors],
        });

        if (type === 'romney') {
            this.configInterface.sendMessage({
                action: 'setGutters',
                params: [false],
            });
        }
    }
}
