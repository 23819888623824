<form [formGroup]="offerOrderLineForm">
    <div class="modal-content">
        <div class="form-group">
            <label class="form-label small-label">{{ 'dealer:offer:order:line:field:title:label' | translate }}</label>
            <div class="input-group small-label"><input type="text" class="form-control" [ngClass]="{'is-invalid': !isFormFieldValid('title')}" formControlName="title" /></div>
        </div>
        <div class="form-group">
            <label class="form-label small-label">{{ 'dealer:offer:order:line:field:price:label' | translate }}</label>
            <div class="input-group small-label"><input type="text" class="form-control" [ngClass]="{'is-invalid': !isFormFieldValid('price')}" formControlName="price" appFormatNumber /></div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            (click)="saveOrderLine()"
            [attr.disabled]="!isFormValid() ? 'disabled' : null"
        >
            {{ (orderLine.id ? 'dealer:offer:order:line:edit:button' : 'dealer:offer:order:line:add:button') | translate }}
        </button>
    </div>
</form>
