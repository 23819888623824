import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import Pica from 'pica';

@Component({
    selector: 'app-image-add',
    templateUrl: './image-add.component.html',
    styleUrls: ['./image-add.component.scss']
})
export class ImageAddComponent {

    public dragOver = false;

    @Input() public image: string;
    @Input() public imageCrop = true;
    @Input() public imageType = 'jpeg';
    @Input() public imageCompression = 0.75;
    @Input() public previewWidth: number;
    @Input() public previewHeight: number;
    @Input() public thumbWidth: number;
    @Input() public thumbHeight: number;
    @Output() private thumbGenerated = new EventEmitter();
    @ViewChild('imageSelector') imageSelector: ElementRef;

    /**
     * Open the native browser file selection dialog by triggering the click event on the hidden input element
     */
    public openSelectImageDialog(): void {
        const fileInput = this.imageSelector.nativeElement as HTMLInputElement;
        fileInput.click();
    }

    public imageDragOver(event): void {
        this.preventDefaultEvents(event);
        this.dragOver = true;
    }

    public imageDragLeave(event): void {
        this.preventDefaultEvents(event);
        this.dragOver = false;
    }

    public imageDropped(event): void {
        this.preventDefaultEvents(event);
        this.processImage(event.dataTransfer.files[0]);
        this.dragOver = false;
    }

    public inputChanged(event): void {
        this.preventDefaultEvents(event);
        this.processImage(event.target.files[0]);
    }

    private preventDefaultEvents(event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Process the selected image file
     *
     * @param imageFile File object from FileList
     */
    private processImage(imageFile: File): void {
        const imageSrc = (window as any).URL.createObjectURL(imageFile);

        this.imageLoader(imageSrc).then((image) => {
            let thumbWidth = this.thumbWidth;
            let thumbHeight = this.thumbHeight;
            const aspectRatios = [thumbWidth / image.width, thumbHeight / image.height];
            const aspectRatio = Math[this.imageCrop ? 'max' : 'min'](...aspectRatios);
            const newWidth = Math.round(aspectRatio * image.width);
            const newHeight = Math.round(aspectRatio * image.height);

            if (!this.imageCrop) {
                thumbWidth = newWidth;
                thumbHeight = newHeight;
            }

            const cropOffset = {
                x: Math.round((thumbWidth - newWidth) / 2),
                y: Math.round((thumbHeight - newHeight) / 2)
            };

            const resizeCanvas = this.createCanvas(newWidth, newHeight);
            const cropCanvas = this.createCanvas(thumbWidth, thumbHeight);

            const thumbResizer = new Pica();
            const thumbGenerator = thumbResizer.resize(image, resizeCanvas) as Promise<void>;
            thumbGenerator.then(() => {
                const thumbSrc = resizeCanvas.toDataURL('image/png');

                this.imageLoader(thumbSrc).then((thumb) => {
                    cropCanvas.getContext('2d').drawImage(
                        thumb,
                        0,
                        0,
                        thumb.width,
                        thumb.height,
                        cropOffset.x,
                        cropOffset.y,
                        thumb.width,
                        thumb.height
                    );
                    this.image = cropCanvas.toDataURL(`image/${this.imageType}`, this.imageCompression);
                    this.thumbGenerated.emit(this.image);

                }, () => {
                    this.removeImage();
                });
            }, () => {
                this.removeImage();
            });
        }, () => {
            this.removeImage();
        });
    }

    /**
     * Remove the image (preview)
     */
    public removeImage(): void {
        this.image = '';
        this.thumbGenerated.emit(this.image);
    }

    /**
     * Create and load an image source
     *
     * @param imageSrc Source to load
     * @returns Promise<HTMLImageElement>
     */
    private imageLoader(imageSrc: any): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const loader = new Image();
            loader.onload = () => resolve(loader);
            loader.onerror = () => reject(loader);
            loader.src = imageSrc;
        });
    }

    /**
     * Create canvas for processing images
     *
     * @param width Width of the created canvas
     * @param height Height of the created canvas
     * @returns HTMLCanvasElement
     */
    private createCanvas(width, height): HTMLCanvasElement {
        const newCanvas = document.createElement('canvas') as HTMLCanvasElement;
        newCanvas.width  = width;
        newCanvas.height = height;
        return newCanvas;
    }

}
