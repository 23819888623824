import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {OrderService} from '../../services/order-service/order-service.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {InfoService} from '../../services/info-service/info-service.service';
import {IDealerInformation} from '../../interfaces/information';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {NotificationService} from '../../services/notification-service';
import {PdfContentService} from '../../services/pdf-content-service';
import {IPrettyScreenshotMessage} from '../../interfaces/configurator-message';
import {promise} from 'protractor';
import {FileUtil} from '../../utils/file.util';

@Component({
    selector: 'app-modal-offer',
    templateUrl: './modal-offer-request.component.html',
})
export class ModalOfferRequestComponent implements OnInit {

    private pdfMake;

    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private infoService: InfoService,
        private configurator: ConfiguratorInterfaceService,
        private router: Router,
        private pdfService: PdfContentService,
    ) {
        this.pdfMake = require('pdfmake/build/pdfmake');
        const baseUrl = window.location.protocol + '//' +
            window.location.host + '/assets/fonts/';

        this.pdfMake.fonts = {
            Nunito: {
                normal: baseUrl + 'NunitoSans-Regular.ttf',
                bold: baseUrl + 'NunitoSans-Bold.ttf',
                italic: baseUrl + 'NunitoSans-Italic.ttf',
                bolditalic: baseUrl + 'NunitoSans-BoldItalic.ttf',
            }
        };
    }

    private info: IDealerInformation;

    public savingForm = false;
    private pdfBlob;

    ngOnInit(): void {
        const data = this.modalService.getData().data;
        this.savingForm = true;

        this.infoService.getDealerInfo().then((info) => {
            this.info = info;

            const subscription = this.configurator.receiveMessage().subscribe((message) => {
                if (message.action === 'requestOfferScreenshots') {
                    subscription.unsubscribe();

                    // Preload the images we need to embed in the background
                    this.bakeBackgroundsIntoScreenshots(message).then((bakedMessage) => {
                        this.pregenerateBlob(this.pdfMake.createPdf(
                            this.pdfService.generateOfferPdf(this.info, bakedMessage)), (blob) => {
                            this.savingForm = false;
                            this.pdfBlob = blob;
                        });
                    });
                }
            });

            // Delay the rendering of the blueprints until the animation for fading in is done
            // To allow the pop up to show while the rendering is being done ( which blocks the UI thread )
            setTimeout(() => {
                this.configurator.sendMessage({
                    action: 'requestOfferScreenshots',
                    params: ['EN']
                });
            }, 500);
        });
    }

    public finish() {
        // we have to open the window immediately and store the reference
        // otherwise popup blockers will stop us
        const win = window.open('', '_blank');
        const urlCreator = window.URL;
        console.log( this.pdfBlob );
        win.location.href = urlCreator.createObjectURL(this.pdfBlob);

        this.savingForm = false;
        this.modalService.close();
    }

    public download() {
        const reader = new FileReader();
        reader.onloadend = () => {
            const today = new Date();
            const dateString = today.toLocaleDateString("en-UK").replace(/\//g, '-');

            FileUtil.saveFile(String(reader.result).replace('data:application/pdf;base64,', ''),
                'application/pdf', 'Confibuild-export-(' + dateString + ').pdf');

            this.savingForm = false;
            this.modalService.close();
        }
        reader.readAsDataURL(this.pdfBlob);
    }

    public close() {
        this.savingForm = false;
        this.modalService.close();
    }

    /**
     * PDFMake does not have multiple layers, so bake the logo etc into the background image instead
     *
     * The background image needs to be 32% in total width from 6% to 12% in total height
     * The first 6.5% of the images need to be a white pane across the entire length
     * The logo Y starts from 3.5% until at most 13.5% of the total height
     * The logo X starts from 2.5% until at most 21.5% of the total width
     * @private
     */
    private bakeBackgroundsIntoScreenshots(message: IPrettyScreenshotMessage): Promise<IPrettyScreenshotMessage> {
        return new Promise<IPrettyScreenshotMessage>((resolve) => {
            let imageLoaded = 0;

            const backgroundImage = new Image();
            const isobackImage = new Image();
            const isofrontImage = new Image();
            const directfrontImage = new Image();
            const logoImage = new Image();

            const bakeBackgroundHeader = (ctx, canvas, image, addUspBackground): string => {
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                const backgroundRatio = canvas.width * 0.32 / backgroundImage.width;
                ctx.drawImage(backgroundImage, 0, Math.floor(canvas.height * 0.059), canvas.width * 0.32,backgroundImage.height * backgroundRatio);
                ctx.fillStyle = 'rgba(255,255,255,255)';
                ctx.fillRect(0, 0, canvas.width, canvas.height * 0.065);

                const logoStartX = canvas.width * 0.03;
                const logoStartY = canvas.height * 0.045;
                const maxLogoWidth = canvas.width * 0.19;
                const maxLogoHeight = canvas.height * 0.105;

                // Resize the logo to the largest possible fit with the original aspect ratio
                const logoWidthAspectRatio = maxLogoWidth / logoImage.width;
                const logoHeightAspectRatio = maxLogoHeight / logoImage.height;
                const logoBothAspectRatio = logoImage.height * logoWidthAspectRatio > logoImage.width * logoHeightAspectRatio ?
                    maxLogoHeight / logoImage.height * logoWidthAspectRatio :
                    maxLogoWidth / logoImage.width * logoHeightAspectRatio;

                if (logoWidthAspectRatio > logoHeightAspectRatio && logoWidthAspectRatio > logoBothAspectRatio &&
                    logoImage.height * logoWidthAspectRatio <= maxLogoHeight) {
                    ctx.drawImage(logoImage, logoStartX, logoStartY,
                        logoImage.width * logoWidthAspectRatio, logoImage.height * logoWidthAspectRatio);
                } else if (logoHeightAspectRatio > logoWidthAspectRatio && logoHeightAspectRatio > logoBothAspectRatio &&
                    logoImage.width * logoHeightAspectRatio <= maxLogoWidth) {
                    ctx.drawImage(logoImage, logoStartX, logoStartY,
                        logoImage.width * logoHeightAspectRatio, logoImage.height * logoHeightAspectRatio);
                } else {
                    ctx.drawImage(logoImage, logoStartX, logoStartY,
                        logoImage.width * logoBothAspectRatio, logoImage.height * logoBothAspectRatio);
                }

                // Bake in the footer margin as well
                ctx.fillRect(0, canvas.height * 0.99, canvas.width, canvas.height);

                // Margin between text and background is 3.4% of the total width
                // Background Y starts at 21% of the total height and ends at 79% of the total height
                // Background X starts at 67% of the total width, and ends at 97% of the total width
                if (addUspBackground) {
                    const sx = canvas.width * 0.67;
                    const dx = canvas.width * 0.97;
                    const sy = canvas.height * 0.21;
                    const dy = canvas.height * 0.79;
                    const r = 15;
                    this.createRoundedRectfunction(ctx, sx, sy, dx, dy, r);

                    // Blur the image behind the background first
                    const blurRadius = 15;
                    ctx.filter = 'blur(' + blurRadius + 'px)';
                    const blurRatio = blurRadius / canvas.height;
                    const imageBlurOffset = image.height * blurRatio / 2;
                    const sourceOffset = blurRadius / 2;
                    ctx.drawImage(image, imageBlurOffset + image.width * 0.67, imageBlurOffset + image.height * 0.19,
                        image.width * 0.3 - imageBlurOffset, image.height * 0.62 - imageBlurOffset, sx + sourceOffset,
                        sy + sourceOffset, dx - sx - sourceOffset * 2, dy - sy - sourceOffset * 2);

                    // Draw the darkblue background
                    ctx.filter = 'none';
                    ctx.fillStyle = 'rgba(0, 56, 85, 0.85)';
                    ctx.fill();
                }

                return canvas.toDataURL();
            };

            const generateNewBackgrounds = () => {
                const canvas = document.createElement('canvas');
                canvas.width = 1190; // A3 render width of PDFMake
                canvas.height = 842; // A3 render height of PDFMake
                const ctx = canvas.getContext('2d');

                message.isofront = bakeBackgroundHeader(ctx, canvas, isofrontImage, false);
                message.isoback = bakeBackgroundHeader(ctx, canvas, isobackImage, true);
                message.directfront = bakeBackgroundHeader(ctx, canvas, directfrontImage, true);

                resolve(message);
            };

            // Load all 5 images in parallel and make sure they are all loaded before baking in the logo etc.
            const onloadListeners = () => {
                imageLoaded++;
                if (imageLoaded >= 5) {
                    generateNewBackgrounds();
                }
            }
            logoImage.onload = onloadListeners;
            backgroundImage.onload = onloadListeners;
            isobackImage.onload = onloadListeners;
            isofrontImage.onload = onloadListeners;
            directfrontImage.onload = onloadListeners;

            isobackImage.src = message.isoback;
            isofrontImage.src = message.isofront;
            directfrontImage.src = message.directfront;
            backgroundImage.src = '/assets/images/pdf-header-background.png';
            logoImage.src = this.info.logo ? this.info.logo : '/assets/images/logo-wide.png';
        });
    }

    /**
     * Pregenerate the BLOB object for the pdf
     */
    pregenerateBlob( pdf, cb ) {
        pdf.getBuffer((result) => {
            let blob;
            try {
                blob = new Blob([result], { type: 'application/pdf' });
            } catch (e) {
                // Old browser which can't handle it without making it an byte array (ie10)
                if (e.name === 'InvalidStateError') {
                    const byteArray = new Uint8Array(result);
                    blob = new Blob([byteArray.buffer], { type: 'application/pdf' });
                } else {
                    this.translateService.get('configurator:pdf:error').subscribe((translation: string) => {
                        this.notificationService.openError(500, translation);
                    });
                }
            }

            cb( blob );
        });
    }

    /**
     * Rounded rect creation based on answer from jhoff
     * @url https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
     * @param ctx
     * @param sx
     * @param sy
     * @param dx
     * @param dy
     * @param r
     * @private
     */
    private createRoundedRectfunction(ctx:CanvasRenderingContext2D, sx: number, sy: number, dx: number, dy: number,
                                      r: number) {
        const r2d = Math.PI/180;
        if( ( dx - sx ) - ( 2 * r ) < 0 ) {
            r = ( ( dx - sx ) / 2 );
        } //ensure that the radius isn't too large for x
        if( ( dy - sy ) - ( 2 * r ) < 0 ) {
            r = ( ( dy - sy ) / 2 );
        } //ensure that the radius isn't too large for y
        ctx.beginPath();
        ctx.moveTo(sx+r,sy);
        ctx.lineTo(dx-r,sy);
        ctx.arc(dx-r,sy+r,r,r2d*270,r2d*360,false);
        ctx.lineTo(dx,dy-r);
        ctx.arc(dx-r,dy-r,r,0,r2d*90,false);
        ctx.lineTo(sx+r,dy);
        ctx.arc(sx+r,dy-r,r,r2d*90,r2d*180,false);
        ctx.lineTo(sx,sy+r);
        ctx.arc(sx+r,sy+r,r,r2d*180,r2d*270,false);
        ctx.closePath();
    }
}
