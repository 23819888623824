import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {OrderService} from '../services/order-service/order-service.service';
import {LoadingSpinnerService} from '../services/loading-spinner.service';

@Injectable({
    providedIn: 'root'
})
export class OrderUuidGuard implements CanActivate {

    constructor(
        private orderService: OrderService,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let canActivate = false;

        if (route.params.uuid && route.params.uuid !== '') {
            this.loadingSpinnerService.show();
            await this.orderService.getOrderConfiguration(route.params.uuid).then(() => {
                // The order is valid / exists
                canActivate = true;
            }).finally(() => {
                this.loadingSpinnerService.hide();
            });
        }

        return canActivate;
    }

}
