<div class="order-version-info" *ngIf="hasOrderUUID">
    <ng-container *ngIf="orderVersion">
        <div class="order-version-info__label">{{ 'dealer:offer:order:configuration:edit:label' | translate }}</div>
        <div class="order-version-info__order">
            {{ 'dealer:offer:order:configuration:edit:offer:label' | translate }} {{ orderVersion.order_id | formatId }}
            &nbsp;|&nbsp;
            {{ 'dealer:offer:order:configuration:edit:version:label' | translate }} {{ orderVersion.version }}
        </div>
        <hr>
        <div class="order-version-info__email"><a href="mailto:{{ orderVersion.email_address }}"><i class="icon">email</i>&nbsp;&nbsp;{{ orderVersion.email_address }}</a></div>

        <hr>
        <button (click)="onAddVersion()" class="btn-primary btn-block">
            {{ 'configurator:modal:add_version:button' | translate }}
        </button>
    </ng-container>
    <app-loading-spinner *ngIf="!orderVersion"></app-loading-spinner>
</div>
