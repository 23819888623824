import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LocalStorage} from 'ngx-webstorage';
import {Observable} from 'rxjs/internal/Observable';
import {AuthenticationService} from '../services/authentication-service';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {

    @LocalStorage() readonly jwtAuthenticationToken: string;

    constructor(
        private authenticationService: AuthenticationService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authenticationService.isAuthenticated()) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.jwtAuthenticationToken}`
                }
            });
        }
        return next.handle(request);
    }
}
