import {IConfiguratorMessage} from '../interfaces/configurator-message';

export class MessageUtil {

    public static isJson(json: string): boolean {
        try {
            return (JSON.parse(json) && !!json);
        } catch (e) {
            return false;
        }
    }

    public static valid(message: IConfiguratorMessage): boolean {
        return message.action && typeof message.action === 'string';
    }

}
