import {Component} from '@angular/core';
import {AuthenticationService} from '../../services/authentication-service';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthRoles} from '../../enums';
import {RouteUtil} from '../../utils';

@Component({
    selector: 'app-account-button',
    templateUrl: './account-button.component.html',
    styleUrls: ['./account-button.component.scss']
})
export class AccountButtonComponent {

    public isOpen = false;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    public isAuthenticated() {
        return this.authenticationService.isAuthenticated();
    }

    public isAuthenticatedAsDealer() {
        return this.authenticationService.userHasRole([AuthRoles.DEALER]);
    }

    public getUserName() {
        return this.authenticationService.getAuthInfo().username;
    }

    public currentPageRouteHasDealerRole(): boolean {
        let pageRequiredDealerRole = false;
        const routeRoles = RouteUtil.getRouteData(this.route.snapshot, 'authSpecificRoles', []);
        if (routeRoles.length > 0) {
            const roleIndex = routeRoles.indexOf(AuthRoles.DEALER);
            pageRequiredDealerRole = roleIndex >= 0;
        }
        return pageRequiredDealerRole;
    }

    public navigateToLogin(): void {
        this.router.navigate(['/login'], {
            queryParams: {
                redirectUrl: this.router.url,
            },
        });
    }

    public logout(): void {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    public open(): void {
        this.isOpen = true;
    }

    public close(): void {
        this.isOpen = false;
    }

}
