<app-sidebar-content>
    <app-sidebar-property-component icon="barnroof" tooltip="configurator:step6:tooltip:roofcolour">
        <app-input-colour-buttons [colours]="colours" [currentColour]="roof" (valueChanged)="changeRoofColour($event)"></app-input-colour-buttons>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="barnwall" tooltip="configurator:step6:tooltip:wallcolour">
        <app-input-colour-buttons [colours]="colours" [currentColour]="wall" (valueChanged)="changeWallColour($event)"></app-input-colour-buttons>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="barnfacade" tooltip="configurator:step6:tooltip:facadecolour">
        <app-input-colour-buttons [colours]="colours" [currentColour]="facade" (valueChanged)="changeFacadeColour($event)"></app-input-colour-buttons>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="corner" tooltip="configurator:step6:tooltip:anglePiececolour">
        <app-input-colour-buttons [colours]="colours" [currentColour]="anglePiece" (valueChanged)="changeanglePieceColour($event)"></app-input-colour-buttons>
    </app-sidebar-property-component>

    <app-sidebar-property-component *ngIf="this.buildingType !== 'romney'" icon="gutter" tooltip="configurator:step6:tooltip:guttercolour">
        <app-input-colour-buttons [colours]="gutterColours" [currentColour]="gutter" (valueChanged)="changegutterColour($event)"></app-input-colour-buttons>
    </app-sidebar-property-component>
</app-sidebar-content>
