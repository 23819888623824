<main>
    <form [formGroup]="loginForm" [ngClass]="{'form-submit-error': loginFormHasError}" class="login-form">
        <div class="login-form__logo"><img *ngIf="dealerInfo" [src]="dealerInfo.logo && dealerInfo.logo !== '' ? dealerInfo.logo : 'assets/images/logo-wide.png'"></div>

        <div class="form-group">
            <div class="input-group no-label">
                <div class="input-group-prepend">
                    <div class="input-group-text"><i class="icon">user</i></div>
                </div>
                <input type="text" class="form-control" [placeholder]="'configurator:form:login:username:placeholder' | translate" [ngClass]="{'is-invalid': loginFormHasError || !isFormFieldValid('username')}" formControlName="username">
            </div>
            <div class="invalid-feedback-block" *ngIf="!isFormFieldValid('username') && getFormFieldError('username') === 'required'">{{ 'configurator:form:field:required' | translate }}</div>
        </div>

        <div class="form-group">
            <div class="input-group no-label">
                <div class="input-group-prepend">
                    <div class="input-group-text"><i class="icon">lock</i></div>
                </div>
                <input type="password" class="form-control" [placeholder]="'configurator:form:login:password:placeholder' | translate" [ngClass]="{'is-invalid': loginFormHasError || !isFormFieldValid('password')}" formControlName="password">
            </div>
            <div class="invalid-feedback-block" *ngIf="!isFormFieldValid('password') && getFormFieldError('password') === 'required'">{{ 'configurator:form:field:required' | translate }}</div>

            <a class="reset-password" [routerLink]="['/reset']">{{ "configurator:password_reset:link" | translate }}</a>
        </div>

        <div class="login-form__buttons">
            <button [attr.disabled]="loginFormSubmission ? 'disabled' : null" class="btn btn-block primary" (click)="login()">
                <div *ngIf="loginFormSubmission" class="loading-spinner-inline"></div>
                {{ 'configurator:form:login:button:submit' | translate }}
            </button>
        </div>
    </form>
</main>
