import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthenticationService} from '../../services/authentication-service';
import {LocalStorage} from 'ngx-webstorage';
import {IDealerInformation, IDealerPrivateInformation} from '../../interfaces/information';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidation} from '../../classes';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../services/notification-service';
import {RouteUtil, FormUtil} from '../../utils';
import {AuthRoles} from '../../enums';
import {InfoService} from '../../services/info-service/info-service.service';

@Component({
    selector: 'app-page-login-component',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent extends FormValidation implements OnInit, OnDestroy {

    @LocalStorage() public readonly dealerInfo: IDealerInformation;

    private subscriptions: Subscription[] = [];

    public loginForm: UntypedFormGroup;
    public loginFormHasError = false;
    public loginFormSubmission = false;
    public redirectUrl: string;

    constructor(
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private router: Router,
        private infoService: InfoService,
    ) {
        super();
    }

    ngOnInit() {
        // Create the reactive form
        this.loginForm = new UntypedFormGroup({
            username: new UntypedFormControl('', [Validators.required]),
            password: new UntypedFormControl('', [Validators.required]),
        });

        // Assign the reactive form for validation
        this.assignFormValidation([this.loginForm]);

        // After an unsuccessful login attempt restore the fornm error state
        this.subscriptions.push(this.loginForm.valueChanges.subscribe(() => {
            this.loginFormHasError = false;
        }));

        this.redirectUrl = RouteUtil.getAuthRedirectUrl(this.route.snapshot);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    public login() {
        if (!this.loginFormSubmission && this.isFormValid()) {
            this.loginFormSubmission = true;
            this.loginForm.disable();

            const username = this.loginForm.get('username').value;
            const password = this.loginForm.get('password').value;

            this.authenticationService.login(username, password)
                .then(() => {
                    return this.getRedirectUrl();
                }).then((redirectUrl) => {
                    this.infoService.getDealerInfo().then(( info: IDealerInformation ) => {
                        if (info.locale != null) {
                            this.translate.use(info.locale);
                        }

                        this.router.navigate([redirectUrl])
                            .then(() => {
                                this.translateService.get('configurator:form:login:message:success').subscribe((translation: string) => {
                                    this.notificationService.openSuccess(translation);
                                });
                            });
                    });
                })
                .catch(() => {
                    this.translateService.get('configurator:form:login:message:error').subscribe((translation: string) => {
                        this.notificationService.openError(401, translation);
                        this.loginFormHasError = true;
                    });
                })
                .finally(() => {
                    this.loginFormSubmission = false;
                    this.loginForm.enable();
                });
        }
    }

    private getRedirectUrl()  {
        if (this.authenticationService.userHasRole([AuthRoles.DEALER])) {
            return this.infoService.getDealerPrivateInfo().then((dealerPrivateInfo: IDealerPrivateInformation) => {
                if (!dealerPrivateInfo.margin) {
                    return Promise.resolve('/dealer/settings');
                } else {
                    return Promise.resolve('/dealer/offers');
                }
            });
        }
        return Promise.resolve(this.redirectUrl);
    }
}
