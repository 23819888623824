import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-color',
    templateUrl: './color.component.html',
    styleUrls: ['./color.component.scss']
})
export class ColorComponent {

    @Input() public color: string;

    constructor() {
    }

}
