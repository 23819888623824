import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {IDealerInformation} from '../interfaces/information';
import {IPrettyScreenshotMessage, IScreenshotMessage} from '../interfaces/configurator-message';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PdfContentService {

    private content = [];

    constructor(
        private datePipe: DatePipe,
        private translationService: TranslateService,
    ) {
    }

    public generateBlueprintPdf(info: IDealerInformation, message: IScreenshotMessage, floorPlan: string, order): any {
        this.addBlueprints(message);
        this.addSpecificationTable(order, info);
        this.addBranding();
        this.addPagebreak();

        this.addFloorplan(floorPlan);
        this.addSpecificationTable(order, info, true);
        this.addBranding();

        const documentDefinition = this.getDocumentDefinition();
        this.clearContents();
        return documentDefinition;
    }

    public generateOfferPdf(info: IDealerInformation, message: IPrettyScreenshotMessage): any {
        this.addFrontPageSpecifications(message);
        this.addConfibuildUrl();
        this.addPagebreak();
        this.addUSPPage([
            {title: 'Main frames', value: 'Cost-effective modular system'},
            {title: 'Purlins', value: 'Wood or steel, affordable and easy to \nhandle'},
            {title: 'Flexibility', value: 'Endless possibilities with standard \nsystem parts'},
            {title: 'Volume', value: 'Compact to transport on a truck or in an \ncontainer'},
            {title: 'Delivery time', value: '2 to 6 weeks through stock and own \nproduction'},
            {title: 'Roof shape', value: 'Romney & Variant: curved, and therefore \nstrong'},
        ]);
        this.addConfibuildUrl();
        this.addPagebreak();
        this.addUSPPage([
            {title: 'Quality', value: 'Proven high quality, since 1935'},
            {title: 'Foundations', value: 'Lightweight or prefab due to small \nspacing of mainframes'},
            {title: 'Assembly', value: 'Very fast to assemble with few mounting \nparts'},
            {title: 'Removable', value: 'Developed as a demountable system'},
            {title: 'Sustainability', value: '100% circular and maintenance-free'},
            {title: 'Made in Holland', value: 'Reliable and reachable'},
        ]);
        this.addConfibuildUrl();
        const documentDefinition = this.getPrettyDocumentDefinition(message, info.logo);
        this.clearContents();
        return documentDefinition;
    }

    private addBranding() {
        this.appendContent({
            columns: [
                {
                    image: 'logo',
                    alignment: 'left',
                    width: 320,
                    height: 100,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 3000,
                        y: 2220
                    }
                }
            ],
            margin: [0, 0, 0, 0]
        });

        this.appendContent({
            columns: [
                {
                    image: 'black',
                    alignment: 'left',
                    width: 3354,
                    height: 1,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 7,
                        y: 7
                    }
                },
                {
                    image: 'black',
                    alignment: 'left',
                    width: 1,
                    height: 2369,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 7,
                        y: 7
                    }
                },
                {
                    image: 'black',
                    alignment: 'left',
                    width: 1,
                    height: 2369,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 3362,
                        y: 7
                    }
                },
                {
                    image: 'black',
                    alignment: 'left',
                    width: 3354,
                    height: 1,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 7,
                        y: 2376
                    }
                }
            ],
            margin: [0, 0, 0, 0]
        });
    }

    private addBlueprints(message) {
        this.appendContent({
            columns: [
                {
                    image: message.left,
                    alignment: 'left',
                    width: 1440,
                    height: 810,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 50,
                        y: 750
                    }
                },
                {
                    image: message.front,
                    alignment: 'left',
                    width: 1440,
                    height: 810,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 1600,
                        y: 50
                    }
                },
                {
                    image: message.right,
                    alignment: 'left',
                    width: 1440,
                    height: 810,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 50,
                        y: 1450
                    }
                },
                {
                    image: message.back,
                    alignment: 'left',
                    width: 1440,
                    height: 810,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 1600,
                        y: 750
                    }
                },
                {
                    image: message.intersection,
                    alignment: 'left',
                    width: 1440,
                    height: 810,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 1600,
                        y: 1450
                    }
                },
                {
                    image: message.top,
                    alignment: 'left',
                    width: 1440,
                    height: 810,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 50,
                        y: 0
                    }
                }
            ],
            margin: [0, 0, 0, 0]
        });
    }

    private addFloorplan(floorplan) {
        this.appendContent({
            columns: [
                {
                    image: floorplan,
                    alignment: 'left',
                    width: 3200,
                    height: 1800,
                    margin: [0, 0, 0, 0],
                    absolutePosition: {
                        x: 50,
                        y: 350
                    }
                }
            ],
            margin: [0, 0, 0, 0]
        });
    }

    private addSpecificationTable(order, info, withScale: boolean = false) {
        this.appendContent({
            absolutePosition: {
                x: 3000,
                y: 2100,
            },
            table: {
                headerRows: 0,
                widths: [100, 120, 100],
                body: [
                    [
                        this.getTitleValueRow('configurator:pdf:order_number', order.order_id.toString().padStart(6, '0')),
                        this.getTitleValueRow('configurator:pdf:date', this.datePipe.transform(new Date(), 'dd-MM-yyyy')),
                        this.getTitleValueRow('configurator:pdf:paper_format', 'A0'),
                    ],
                    [
                        this.getTitleValueRow('configurator:pdf:dealer', info.name),
                        this.getTitleValueRow('configurator:pdf:generated_by', 'Confibuild'),
                        !withScale ? this.getTitleValueRow('configurator:pdf:model', order.configuration.template.model) :
                            this.getTitleValueRow('configurator:pdf:scale', '1:100'),
                    ]
                ]
            }
        });
    }

    private appendContent(content) {
        this.content.push(content);
    }

    private getTitleValueRow(title: string, value: string) {
        return [{text: this.getTranslationString(title), bold: true, fontSize: 8}, {text: value, fontSize: 18}];
    }

    private addConfibuildUrl() {
        this.content.push([{
            text: 'www.confibuild.com',
            color: '#FFFFFF',
            bold: true,
            fontSize: 16,
            absolutePosition: {
                x: 1190 - 195,
                y: 841 - 72
            }
        }]);
    }

    private addFrontPageSpecifications(message: IPrettyScreenshotMessage) {
        let leftPositionX = 1190 * 0.033;
        this.content.push([
            {
                text: message.model,
                color: '#FFFFFF',
                bold: true,
                fontSize: 32,
                absolutePosition: {
                    x: leftPositionX,
                    y: 841 - 225
                }
            }
        ]);

        // Add three columns
        const tableData = [
            [
                {image: 'depth', text: message.depth},
                {image: 'width', text: message.width},
                {image: 'height', text: message.height}
            ],
            [
                {image: 'sidewall_colour', text: message.sideWallColour},
                {image: 'roof_colour', text: message.roofColour},
                {image: 'facade_colour', text: message.facadeColour}
            ],
            [
                {image: 'anglepiece_colour', text: message.anglePieceColour},
                {image: 'snowload', text: message.snowLoad},
                {image: 'windload', text: message.windLoad}
            ]
        ];

        for (let i = 0; i < tableData.length; i++) {
            let contentY = 841 - 150;
            const columnData = tableData[i];
            for (let j = 0; j < columnData.length; j++) {
                this.content.push([{
                    image: columnData[j].image,
                    width: 30,
                    height: 30,
                    absolutePosition: {
                        x: leftPositionX,
                        y: contentY
                    }
                }, {
                    text: columnData[j].text,
                    bold: true,
                    color: '#FFFFFF',
                    fontSize: 14,
                    absolutePosition: {
                        x: leftPositionX + 43,
                        y: contentY + 5
                    }
                }]);

                contentY += 40;
            }

            leftPositionX += 130;
        }
    }

    private addUSPPage(usps: {title: string, value: string}[]) {
        // Margin between text and background is 3.4% of the total width
        // Background Y starts at 21% of the total height and ends at 79% of the total height
        // Background X starts at 67% of the total width, and ends at 97% of the total width
        let textX = 1190 * (0.67 + 0.034);
        let textY = 841 * (0.21 + 0.034);
        let textTotalHeight = 841 * (0.58 - 0.034 * 2)

        for (let i = 0, length = usps.length; i < length; i++) {
            const usp = usps[i];

            const lineCount = usp.title.split(/\r\n|\r|\n/).length + usp.value.split(/\r\n|\r|\n/).length;
            const textYOffset = lineCount >= 3 ? 0 : 14;

            this.content.push([
                {
                    text: usp.title,
                    color: '#76ad1c',
                    fontSize: 14,
                    absolutePosition: {
                        x: textX,
                        y: textY + textYOffset
                    }
                },
                {
                    text: usp.value,
                    color: '#FFFFFF',
                    fontSize: 14,
                    absolutePosition: {
                        x: textX,
                        y: textY + 22 + textYOffset
                    }
                },
            ]);

            textY += textTotalHeight / 6
        }
    }

    private clearContents() {
        this.content = [];
    }

    private getTranslationString(translationKey: string) {
        if (this.translationService.translations[this.translationService.currentLang].hasOwnProperty(translationKey)) {
            return this.translationService.translations[this.translationService.currentLang][translationKey];
        } else {
            return translationKey;
        }
    }

    private addPagebreak() {
        this.appendContent({
            columns: [],
            pageBreak: 'after'
        });
    }

    private getDocumentDefinition() {
        return {
            pageSize: 'A0',
            pageOrientation: 'landscape',
            pageMargins: [0, 0, 0, 0],
            content: this.content,
            styles: {
                header: {
                    fontSize: 18,
                    lineHeight: 1.2
                },
                title: {
                    fontSize: 32,
                    bold: true,
                    color: '#346B46'
                },
                tabletext: {
                    fontSize: 14
                },
                bold: {
                    bold: true
                }
            },
            images: {
                logo: require('../assets/images/logo-base64.json').logo,
                black: require('../assets/images/pixel-base64.json').black,
            },
            defaultStyle: {
                font: 'Nunito'
            }
        };
    }

    private getPrettyDocumentDefinition(images: IPrettyScreenshotMessage, logoUrl: string) {
        const pdfIconImages = require('../assets/images/pdf-icon-base64.json');

        return {
            pageSize: 'A3',
            pageOrientation: 'landscape',
            background: function (currentPage, pageSize) {
                let backgroundImage = '';
                if (currentPage === 1) {
                    backgroundImage = images.isofront;
                } else if (currentPage === 2) {
                    backgroundImage = images.isoback;
                } else if (currentPage === 3) {
                    backgroundImage = images.directfront;
                }

                return [{
                    image: backgroundImage,
                    width: pageSize.width,
                    height: pageSize.height
                }]
            },
            pageMargins: [0, 0, 0, 0],
            content: this.content,
            styles: {
                header: {
                    fontSize: 18,
                    lineHeight: 1.2
                },
                title: {
                    fontSize: 32,
                    bold: true,
                    color: '#346B46'
                },
                tabletext: {
                    fontSize: 14
                },
                bold: {
                    bold: true
                }
            },
            images: {
                logo: logoUrl ? logoUrl : require('../assets/images/logo-base64.json').logo,
                black: require('../assets/images/pixel-base64.json').black,
                width: pdfIconImages.width,
                depth: pdfIconImages.depth,
                height: pdfIconImages.height,
                sidewall_colour: pdfIconImages.sidewall_colour,
                roof_colour: pdfIconImages.roof_colour,
                facade_colour: pdfIconImages.facade_colour,
                anglepiece_colour: pdfIconImages.anglepiece_colour,
                snowload: pdfIconImages.snowload,
                windload: pdfIconImages.windload
            },
            defaultStyle: {
                font: 'Nunito'
            }
        };
    }
}
