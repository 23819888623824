import {AbstractControl, ValidationErrors} from '@angular/forms';

export class FormUtil {

    public static numberValidator(control: AbstractControl): ValidationErrors | null {
        const value = (control.value ? control.value.toString() : '').replace(/\.|,/g, '');
        const valid = /^([-]?([1-9]|[1-9][0-9]+))$/.test(value);
        return value === '' || valid ? null : {invalidNumber: true};
    }

    public static phoneValidator(control: AbstractControl): ValidationErrors | null {
        const value = (control.value || '');

        // Regex to match the following phone numbers while disallowing letters etc
        // +31612312311
        // +31 (06)12312311
        // +31 (0)123 12 31 11
        // 06123123
        // 06 123 123 11
        // 06 123-123-11
        // (0474) 554432
        const valid = /^[+]?[0-9]?[0-9\s]+?[(]?[0-9]{1,4}[)]?[-\s\.0-9]+$/.test(value);
        return value === '' || valid ? null : {invalidPhone: true};
    }

    public static numberOnlyInput(control: AbstractControl): void {
        const controlValue = (control.value || '');
        let value = controlValue.replace(/[^0-9,]/g, '');

        // Only 1 comma allowed
        const parts = value.split(',');
        if (parts.length > 1) {
            value = parts.slice(0, -1).join('') + ',' + parts.slice(-1);
        }

        if (controlValue !== value) {
            control.setValue(value);
        }
    }

    public static numberFormatterInput(control: AbstractControl, locale: string): void {
        const value = (control.value || '').replace(/\.|,/g, '');
        const numberValue = FormUtil.convertToNumber(value);
        const formattedValue = numberValue.toLocaleString(locale);
        if (value !== '' && !isNaN(numberValue)) {
            control.setValue(formattedValue);
        }
    }

    public static euroFormatterInput(control: AbstractControl, locale: string): void {
        const value = (control.value || '').replace(/\.|,/g, '');
        const numberValue = FormUtil.convertToNumber(value);
        const formattedValue = numberValue.toLocaleString(locale);
        if (value !== '' && !isNaN(numberValue)) {
            const formattedEuro = '€ ' + formattedValue;
            control.setValue(formattedEuro);
        }
    }

    public static emailValidator(control: AbstractControl): ValidationErrors | null {
        const value = (control.value || '');
        const valid = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value);
        return value === '' || valid ? null : {invalidEmail: true};
    }

    public static convertToNumber(value: string | number): number {
        return Number((`${value}` || '').replace(/\.|,/g, ''));
    }

    public static convertEuroToEurocents(value: string| number): number {
        return FormUtil.convertToNumber(value) * 100;
    }

    public static convertEurocentsToEuro(value: string | number): number {
        return FormUtil.convertToNumber(value) / 100;
    }

    public static convertMillimeterToMeter(value: string | number): number {
        return FormUtil.convertToNumber(value) / 1000;
    }

}
