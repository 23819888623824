import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication-service';

@Injectable({
  providedIn: 'root'
})
export class SundayGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    canActivate(): boolean {
        const date = new Date();

        if (!this.authenticationService.isAuthenticated()) {
            // Redirect if the day is sunday
            if (date.getDay() === 0) {
                this.router.navigate(['sunday']);
                return false;
            }
        }

        return true;
    }
}
