import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-dealer-menu',
    templateUrl: './dealer-menu.component.html',
    styleUrls: ['./dealer-menu.component.scss']
})
export class DealerMenuComponent implements OnInit, OnDestroy {

    private routeChangedSubscription: Subscription;

    public routerLinkActiveOffer: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.setMenuLinkActive();
        this.routeChangedSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setMenuLinkActive();
            }
        });
    }

    ngOnDestroy() {
        this.routeChangedSubscription.unsubscribe();
    }

    private setMenuLinkActive(): void {
        const uriSegments = this.route.snapshot.url;
        const path = uriSegments.join('/');
        this.routerLinkActiveOffer = path.startsWith('dealer/offer/');
    }

}
