import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-input-slider',
    templateUrl: './input-slider.component.html',
    styleUrls: ['./input-slider.component.scss']
})
export class InputSliderComponent {
    @Input() steps: number[];
    @Input()
    set value(value: number) {
        this.currentValue = this.steps.indexOf(value);
    }
    @Output() valueChanged = new EventEmitter<number>();
    @Input() unit: string;
    @Input() forceSteps = false;

    public currentValue: number;
    public sliderActiveTimoutID: any;
    public sliderActive: boolean;

    constructor() {
    }

    public getStepMargin(index) {
        return `${(100 / (this.steps.length - 1)) * index}%`;
    }

    public changedValue(value) {
        this.valueChanged.emit(value);
    }

    public getTooltipMargin() {
        const min = this.steps[0];
        const max = this.steps[1];
        const diff = max - min;
        return `${(this.currentValue - min) / diff * 100}%`;
    }

    public activeSlider() {
        if (this.sliderActiveTimoutID) {
            window.clearTimeout(this.sliderActiveTimoutID);
        }
        this.sliderActiveTimoutID = window.setTimeout(() => {
            this.sliderActive = false;
        }, 600);
        this.sliderActive = true;
    }
}
