import {Component, OnInit, OnDestroy} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {IDealerInformation, IDealerPrivateInformation} from '../../interfaces/information';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidation} from '../../classes';
import {Subscription} from 'rxjs/internal/Subscription';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../services/notification-service';
import {ApiService} from '../../services/api-service/api-service.service';
import {FormUtil} from '../../utils';

@Component({
    selector: 'app-page-reset-component',
    templateUrl: './page-reset.component.html',
    styleUrls: ['./page-reset.component.scss']
})
export class PageResetComponent extends FormValidation implements OnInit, OnDestroy {

    @LocalStorage() public readonly dealerInfo: IDealerInformation;

    private subscriptions: Subscription[] = [];

    public resetForm: UntypedFormGroup;
    public resetFormHasError = false;
    public resetFormSubmission = false;
    public resetSuccessful = false;

    constructor(
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private apiService: ApiService,
    ) {
        super();
    }

    ngOnInit() {
        // Create the reactive form
        this.resetForm = new UntypedFormGroup({
            emailAddress: new UntypedFormControl('', {
                validators: [Validators.required, FormUtil.emailValidator],
                updateOn: 'blur'
            })
        });

        // Assign the reactive form for validation
        this.assignFormValidation([this.resetForm]);

        // After an unsuccessful reset attempt restore the fornm error state
        this.subscriptions.push(this.resetForm.valueChanges.subscribe(() => {
            this.resetFormHasError = false;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    public reset() {
        if (!this.resetFormSubmission && this.isFormValid()) {
            this.resetFormSubmission = true;
            this.resetForm.disable();

            const emailAddress = this.resetForm.get('emailAddress').value;

            this.apiService.post('/user/reset', {
                emailAddress: emailAddress
            }).then(() => {
                this.resetFormHasError = false;
                this.resetFormSubmission = false;
                this.resetSuccessful = true;
            });
        }
    }
}
