import {Component, ViewChild, ComponentFactoryResolver, ViewContainerRef} from '@angular/core';
import {ModalModel} from '../../model/modal-model';
import {IModalData} from '../../interfaces/modal';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements IModalData {

    public title: string;
    public template: string;
    public closeable: boolean;
    public component: any;

    @ViewChild('modalComponentHost', {static: true, read: ViewContainerRef}) vc: ViewContainerRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {
    }

    public setData(settings: ModalModel) {
        this.title = settings.title;
        this.template = settings.template;
        this.closeable = settings.closeable;
        this.component = settings.component;

        if (this.component) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
            this.vc.clear();
            this.vc.createComponent(componentFactory);
        }
    }

}
