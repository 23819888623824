import {Component} from '@angular/core';

@Component({
    selector: 'app-input-decimal',
    templateUrl: './input-decimal.component.html',
    styleUrls: ['./input-decimal.component.scss']
})
export class InputDecimalComponent {

}
