import {Component, OnDestroy, OnInit} from '@angular/core';
import {IDealerInformation} from '../../interfaces/information';
import {LocalStorage} from 'ngx-webstorage';
import {OrderService} from '../../services/order-service/order-service.service';

@Component({
    selector: 'app-page-offer-send-component',
    templateUrl: './page-offer-send.component.html',
    styleUrls: ['./page-offer-send.component.scss']
})
export class PageOfferSendComponent implements OnInit, OnDestroy {

    @LocalStorage() public readonly dealerInfo: IDealerInformation;

    public offerUUID: string;
    public offerEmail: string;

    constructor(
        private orderService: OrderService,
    ) {
    }

    ngOnInit() {
        document.documentElement.classList.add('page-clean');

        const currentOrder = this.orderService.getLastCreatedOrder();
        this.offerUUID = currentOrder.uuid;
        this.offerEmail = currentOrder.email_address;
    }

    ngOnDestroy() {
        document.documentElement.classList.remove('page-clean');
    }

}
