import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-input-button-toggle',
    templateUrl: './input-button-toggle.component.html',
    styleUrls: ['./input-button-toggle.component.scss']
})
export class InputButtonToggleComponent implements OnInit {

    @Input() values: string[];
    @Input() currentValue: string;
    @Output() valueChanged = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
        if (!this.currentValue) {
            this.currentValue = this.values[0];
        }
    }

    changedValue(value: string) {
        this.currentValue = value;
        this.valueChanged.emit(value);
    }
}
