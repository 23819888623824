import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatDecimal'
})
export class FormatDecimalPipe implements PipeTransform {

    transform(value: number, precision: number): number {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
}
