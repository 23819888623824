import {Injectable} from '@angular/core';
import {ApiService} from '../api-service/api-service.service';
import {LocalStorage, SessionStorage} from 'ngx-webstorage';
import {IOrder, IOrderConfiguration, IOrderVersion, IUpdateOrder, OrderStatuses} from '../../interfaces/order';
import {AuthenticationService} from '../authentication-service';
import {AuthRoles, OrderStatus} from '../../enums';
import {OrderUtil} from '../../utils';
import {StatePersistorService} from '../state-persistor/state-persistor.service';
import {OrderConfigurationDataMapper} from '../order-configuration-data-mapper/order-configuration-data-mapper';

/**
 * Class for creating and retrieving orders from the server
 */
@Injectable()
export class OrderService {

    private orderConfigurations: IOrderConfiguration[] = [];
    private currentOrderConfiguration: IOrderConfiguration;

    constructor(
        private apiService: ApiService,
        private orderConfigurationMapperService: OrderConfigurationDataMapper,
        private authenticationService: AuthenticationService,
        private statePersistorService: StatePersistorService,
    ) {
    }

    @LocalStorage('affiliateId') private readonly affiliateId;
    @SessionStorage('lastCreatedOrder', {
        id: 0,
        name: '',
        email_address: '',
        phone: '',
        status: OrderStatus.NEW,
        updated_at: '',
        created_at: ''
    }) private lastCreatedOrder: IOrder;

    /**
     * Calculate the price using the given price json
     */
    public createOrder(
        name: string,
        email_address: string,
        phone: string,
        construction_site: string,
        createAccount: boolean): Promise<IOrder> {
        const newOrder = {
            name: name,
            email_address: email_address,
            phone: phone,
            constructionSite: construction_site,
            shouldCreateAccount: createAccount,
            configuration: this.orderConfigurationMapperService.forPost(this.statePersistorService.retrieveState()),
        };

        let url = '/order';

        if (this.affiliateId !== '') {
            url += `?affiliate=${this.affiliateId}`;
        }

        return this.apiService.post(url, newOrder).then((response: any) => {
            this.lastCreatedOrder = response.body;
            return this.lastCreatedOrder;
        });
    }

    /**
     * Get the last created order in this session
     */
    public getLastCreatedOrder(): IOrder {
        return this.lastCreatedOrder;
    }

    /**
     * Get the last used order configuration
     */
    public getCurrentOrderConfiguration(): IOrderConfiguration {
        return this.currentOrderConfiguration;
    }

    /**
     * Get a list of orders from the backend
     */
    public getOrders(status?: OrderStatuses): Promise<IOrder[]> {
        let url = '/dealer/orders';
        if (status) {
            url += `?status=${status}`;
        }
        return new Promise((resolve, reject) => {
            this.apiService.get(url)
                .then((response) => {
                    resolve(response.body);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public searchOrders(query: string, selectedColumn: string, orderBy: string, status?: OrderStatuses): Promise<IOrder[]> {
        let url = '/dealer/orders';
        url += `?query=${query}`;
        if (selectedColumn) {
            url += `&selectedColumn=${selectedColumn}`;
        }
        if (orderBy) {
            url += `&orderBy=${orderBy}`;
        }
        if (status) {
            url += `&status=${status}`;
        }
        return new Promise((resolve, reject) => {
            this.apiService.get(url)
                .then((response) => {
                    resolve(response.body);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Get a single detailed order with configuration and order lines
     */
    public getDetailedOrder(id: number): Promise<IOrder> {
        const url = `/orders/${id}`;
        return this.apiService.get(url).then((request: any) => {
            return request.body;
        });
    }

    /**
     * Update a single order
     */
    public updateOrder(id: number, updateOrder: IUpdateOrder): Promise<any> {
        const url = `/orders/${id}`;
        return this.apiService.put(url, updateOrder).then((request: any) => {
            return request.body;
        });
    }

    /**
     * Create a new version for an order
     */
    public createOrderVersion(id: number, updateOrder: IUpdateOrder): Promise<any> {
        const url = `/orders/version/${id}`;
        return this.apiService.post(url, updateOrder).then((request: any) => {
            return request.body;
        });
    }

    /**
     * Get the customer configuration including the affiliateId
     */
    public getOrderConfiguration(uuid: string, useCached: boolean = true): Promise<IOrderConfiguration> {
        return new Promise((resolve, reject) => {
            const cachedOrderConfiguration = this.orderConfigurations.find((orderConfiguration) => {
                return orderConfiguration.uuid === uuid;
            });
            if (useCached && cachedOrderConfiguration !== undefined) {
                resolve(cachedOrderConfiguration);
            } else {
                const url = `/configuration/${uuid}`;
                this.apiService.get(url).then((request: any) => {
                    const orderConfigurationData = this.orderConfigurationMapperService.fromGet(request.body.configuration);
                    const orderConfiguration = request.body;
                    orderConfiguration.configuration = orderConfigurationData;
                    this.orderConfigurations.push(orderConfiguration);

                    this.currentOrderConfiguration = orderConfiguration;
                    resolve(orderConfiguration);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }

    /**
     * Get the order version
     */
    public getOrderVersion(uuid: string): Promise<IOrderVersion> {
        const url = `/orders/version/${uuid}`;
        return this.apiService.get(url).then((request: any) => {
            return request.body;
        });
    }

    /**
     * Get the order version
     */
    public getDownloadOrder(uuid: string): Promise<any> {
        const url = `/download/version/${uuid}`;
        return this.apiService.get(url).then((request: any) => {
            return request.body;
        });
    }

    /**
     * Determine if the dealer can edit the order
     */
    public dealerCanEditOrder(uuid: string): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.authenticationService.userHasRole([AuthRoles.DEALER])) {
                resolve(false);
            } else {
                this.getOrderConfiguration(uuid, false).then((orderConfiguration) => {
                    const authInfo = this.authenticationService.getAuthInfo();
                    resolve(
                        orderConfiguration.affiliate_id === authInfo.affiliateId &&
                        !OrderUtil.isReadOnly(orderConfiguration.order_status)
                    );
                })
                .catch(() => {
                    resolve(false);
                });
            }
        });
    }

    /**
     * Only configurations connected to the respective dealers can have blueprints made
     */
    public blueprintRequestCanBeMadeForOrder(uuid: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.getOrderConfiguration(uuid, false).then((orderConfiguration) => {
                const authInfo = this.authenticationService.getAuthInfo();
                resolve(
                    authInfo !== null && ( authInfo.roles.includes(AuthRoles.ADMIN) ||
                        ( authInfo.affiliateId !== null && orderConfiguration.affiliate_id === authInfo.affiliateId ) )
                );
            })
            .catch(() => {
                resolve(false);
            });
        });
    }

}
