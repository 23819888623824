<div class="modal-title"><span class="text-primary">{{ 'configurator:modal:finish:green_title' | translate }}</span> {{ 'configurator:modal:finish:rest_of_title' | translate }}</div>
<div class="modal-template">
    <form [formGroup]="finishForm">
        <div class="form-group-modal">
            <label class="form-label" for="name_field">{{ 'configurator:modal:finish:name' | translate }}</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFormFieldValid('name')}" id="name_field" formControlName="name">
            <div class="text-error" *ngIf="!isFormFieldValid('name')">{{ 'configurator:modal:finish:name_error' | translate }}</div>
        </div>
        <div class="form-group-modal">
            <label class="form-label" for="email_field">{{ 'configurator:modal:finish:email' | translate }}</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFormFieldValid('email')}" id="email_field" formControlName="email">
            <div class="text-error" *ngIf="!isFormFieldValid('email')">{{ 'configurator:modal:finish:email_error' | translate }}</div>
        </div>
        <div class="form-group-modal">
            <label class="form-label" for="phone_field">{{ 'configurator:modal:finish:phone' | translate }}</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFormFieldValid('phone')}" id="phone_field" formControlName="phone">
            <div class="text-error" *ngIf="!isFormFieldValid('phone')">{{ 'configurator:modal:finish:phone_error' | translate }}</div>
        </div>
        <div class="form-group-modal">
            <label class="form-label" for="construction_site">{{ 'configurator:modal:finish:construction_site' | translate }}</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFormFieldValid('construction_site')}" id="construction_site" formControlName="construction_site">
            <div class="text-error" *ngIf="!isFormFieldValid('construction_site')">{{ 'configurator:modal:finish:construction_site_error' | translate }}</div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="text-error" *ngIf="serverError">
        {{ 'configurator:modal:finish:server_error' | translate }}
    </div>
    <button (click)="finish()" class="btn-primary btn-block btn-large" [attr.disabled]="!isFormValid() || savingForm ? 'disabled' : null">
        <div *ngIf="savingForm" class="loading-spinner-inline"></div>
        {{ 'configurator:modal:finish:button' | translate }}
    </button>
</div>
