import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'formatEuroCents'
})
export class FormatEuroCentsPipe implements PipeTransform {

    constructor(
        private translate: TranslateService,
    ) {
    }

    transform(value: number, roundToEuros?: boolean): string {
        if (!value) {
            return value === 0 ? value.toString() : '';
        }
        if (isNaN(value)) {
            return value.toString();
        }

        roundToEuros = roundToEuros ? roundToEuros : true;

        const eurosPart = roundToEuros ? Math.round(value / 100) : Math.floor(value / 100);
        const centsPart = roundToEuros ? 0 : value % 100;

        let formattedEurosValue = Number(eurosPart).toLocaleString(this.translate.getDefaultLang(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
        // SNOEI-623 show spaces between digits..
        formattedEurosValue = formattedEurosValue.replace('.', ' ').replace(',', ' ');

        let formattedCentsValue = Number(centsPart / 100).toLocaleString(this.translate.getDefaultLang(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).substr(1);

        if (formattedCentsValue.endsWith('00')) {
            // if it's a round number, then show ,- instead of ,00
            formattedCentsValue = formattedCentsValue.replace('00', '-');
        }

        return '€ ' + formattedEurosValue + formattedCentsValue;
    }

}
