import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-sidebar-header-component',
    templateUrl: './sidebar-header.component.html',
    styleUrls: ['./sidebar-header.component.scss']
})
export class SidebarHeaderComponent {

    @Input() icon: string;
    @Input() name: string;

}
