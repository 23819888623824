import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IOrderVersion} from '../../interfaces/order';
import {OrderService} from '../../services/order-service/order-service.service';
import {ActivatedRoute} from '@angular/router';
import {SnapshotManagerService} from '../../services/snapshot-manager/snapshot-manager.service';

@Component({
    selector: 'app-order-version-info',
    templateUrl: './order-version-info.component.html',
    styleUrls: ['./order-version-info.component.scss']
})
export class OrderVersionInfoComponent implements OnInit {

    public orderUUID: string;
    public hasOrderUUID: boolean;
    public orderVersion: IOrderVersion;

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private snapShotManagerService: SnapshotManagerService,
    ) {
    }

    @Output() addVersion: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.orderUUID = this.route.snapshot.paramMap.get('uuid') || '';
        this.hasOrderUUID = this.orderUUID !== '';

        if (this.hasOrderUUID) {
            this.orderService.getOrderVersion(this.orderUUID).then((orderVersion) => {
                this.orderVersion = orderVersion;
            });
            this.orderService.getOrderConfiguration(this.orderUUID).then((orderConfiguration) => {
                this.snapShotManagerService.loadConfigurationData(orderConfiguration.configuration);
            });
        }
    }

    public onAddVersion(): void {
        this.addVersion.emit({});
    }

}
