 import {INotificationData} from '../interfaces/notification';

export class NotificationModel {
    public type: string;
    public duration: number;
    public icon: any;
    public code: string;
    public message: string;
    public dismiss: boolean;

    constructor(data: INotificationData) {
        this.type = data.type || 'default';
        this.duration = data.duration || 4000;
        this.icon = data.icon || false;
        this.code = data.code || '';
        this.message = data.message || '';
        this.dismiss = data.dismiss || false;
    }
}
