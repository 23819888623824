import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {OrderService} from '../../services/order-service/order-service.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {InfoService} from '../../services/info-service/info-service.service';
import {IDealerInformation} from '../../interfaces/information';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {NotificationService} from '../../services/notification-service';
import {PdfContentService} from '../../services/pdf-content-service';

@Component({
    selector: 'app-modal-blueprint',
    templateUrl: './modal-blueprint-request.component.html',
})
export class ModalBlueprintRequestComponent implements OnInit {

    private pdfMake;

    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private infoService: InfoService,
        private orderService: OrderService,
        private configurator: ConfiguratorInterfaceService,
        private router: Router,
        private pdfService: PdfContentService,
    ) {
        this.pdfMake = require('pdfmake/build/pdfmake');
        const baseUrl = window.location.protocol + '//' +
            window.location.host + '/assets/fonts/';

        this.pdfMake.fonts = {
            Nunito: {
                normal: baseUrl + 'NunitoSans-Regular.ttf',
                bold: baseUrl + 'NunitoSans-Bold.ttf',
                italic: baseUrl + 'NunitoSans-Italic.ttf',
                bolditalic: baseUrl + 'NunitoSans-BoldItalic.ttf',
            }
        };
    }

    private order;
    private info: IDealerInformation;

    public savingForm = false;
    public serverError = false;
    private pdfBlob;

    ngOnInit(): void {
        const data = this.modalService.getData().data;
        this.order = data.order;
        this.savingForm = true;

        this.infoService.getDealerInfo().then((info) => {
            this.info = info;

            // Due to a limitation in PDFMAKE we need to convert the SVG to PNG
            const loadSvgToPng = (src) => {
                const img = new Image();
                const promise = new Promise((resolve, reject) => {
                    img.onload = () => {
                        const canvas: HTMLCanvasElement = document.createElement('canvas');
                        canvas.width = 3200;
                        canvas.height = 1800;

                        // Make sure the images are always 1:100 in scale
                        const baseImageScale = 58.14;
                        const newImageScale = baseImageScale / 100;
                        const destinationWidth = img.width * newImageScale;
                        const destinationHeight = img.height * newImageScale;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, ( canvas.width - destinationWidth ) / 2,
                            (canvas.height - destinationHeight ) / 2, destinationWidth, destinationHeight);

                        const png = canvas.toDataURL('image/png');
                        resolve(png);
                    };

                    img.onerror = reject;
                });

                img.src = 'data:image/svg+xml;base64,' + btoa(src);
                return promise;
            };

            const subscription = this.configurator.receiveMessage().subscribe((message) => {
                if (message.action === 'requestScreenshots') {
                    subscription.unsubscribe();

                    // Convert the SVG to PNG first before the PDF can be rendered
                    loadSvgToPng(message.floor).then((floorplan: string) => {
                        this.pregenerateBlob(this.pdfMake.createPdf(
                            this.pdfService.generateBlueprintPdf(this.info, message, floorplan, this.order)), (blob) => {
                            this.savingForm = false;
                            this.pdfBlob = blob;
                        });
                    });
                }
            });

            // Delay the rendering of the blueprints until the animation for fading in is done
            // To allow the pop up to show while the rendering is being done ( which blocks the UI thread )
            setTimeout(() => {
                this.configurator.sendMessage({
                    action: 'requestScreenshots',
                    params: ['EN']
                });
            }, 500);
        });
    }

    public finish() {
        // we have to open the window immediately and store the reference
        // otherwise popup blockers will stop us
        const win = window.open('', '_blank');
        const urlCreator = window.URL;
        win.location.href = urlCreator.createObjectURL(this.pdfBlob);

        this.savingForm = false;
        this.modalService.close();
    }

    public close() {
        this.savingForm = false;
        this.modalService.close();
    }

    public isFormValid() {
        return true;
    }

    /**
     * Pregenerate the BLOB object for the pdf
     */
    pregenerateBlob( pdf, cb ) {
        pdf.getBuffer((result) => {
            let blob;
            try {
                blob = new Blob([result], { type: 'application/pdf' });
            } catch (e) {
                // Old browser which can't handle it without making it an byte array (ie10)
                if (e.name === 'InvalidStateError') {
                    const byteArray = new Uint8Array(result);
                    blob = new Blob([byteArray.buffer], { type: 'application/pdf' });
                } else {
                    this.translateService.get('configurator:pdf:error').subscribe((translation: string) => {
                        this.notificationService.openError(500, translation);
                    });
                }
            }

            cb( blob );
        });
    }
}
