import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NotificationModel} from '../model/notification-model';
import {INotificationData} from '../interfaces/notification';

@Injectable()
export class NotificationService {

    private toastRef;
    private toastTid;

    constructor(
        private toastService: ToastrService
    ) {

    }

    /**
     * Show a toast with the notification
     */
    public open(notification: INotificationData): void {
        this.close();
        const settings = new NotificationModel(notification);
        let template = `${settings.message}`;
        if (settings.icon) {
            template = `<i class="icon">${settings.icon}</i>` + template;
        }
        if (settings.code) {
            template += `<small>${settings.code}</small>`;
        }
        this.toastRef = this.toastService.show(`${template}`, '', {
            toastClass: `notification-${settings.type} notification-${settings.icon ? 'has-icon' : 'no-icon'}`,
            positionClass: 'notification-bottom',
            titleClass: 'notification-title',
            messageClass: 'notification-message',
            enableHtml: true,
            disableTimeOut: true,
            closeButton: settings.dismiss,
            tapToDismiss: settings.dismiss
        });
        this.toastTid = setTimeout(() => {
            this.close();
        }, settings.duration);
    }

    /**
     * Utility method for error toast
     */
    public openError(errorCode, message, duration = 8000): void {
        this.open({
            type: 'error',
            duration: duration,
            icon: 'alert',
            code: `${errorCode}`,
            message: message,
            dismiss: true
        });
    }

    /**
     * Utility method for success toast
     */
    public openSuccess(message, duration = 8000): void {
        this.open({
            type: 'success',
            icon: 'check',
            message: message,
            duration: duration
        });
    }

    /**
     * Close any visible toast
     */
    private close(): void {
        if (this.toastRef) {
            this.toastService.clear(this.toastRef.toastId);
            this.toastRef = undefined;
        }
        if (this.toastTid) {
            clearTimeout(this.toastTid);
            this.toastTid = undefined;
        }
    }

}
