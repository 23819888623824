import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {MessageTypes} from '../../enums';

@Component({
    selector: 'app-viewpoint',
    templateUrl: './viewpoint.component.html',
    styleUrls: ['./viewpoint.component.scss']
})
export class ViewpointComponent implements OnInit, OnDestroy {

    public viewpoint = 'presentation';
    public viewpointHover = 'none';
    private subscriptions: Subscription[] = [];

    constructor(
        private configuratorService: ConfiguratorInterfaceService,
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.configuratorService.receiveMessage().subscribe((data) => {
            if (data.action === MessageTypes.CAMERA_SEND) {
                this.viewpoint = data.viewpoint;
            }
        }));

        this.configuratorService.sendMessage({
            action: MessageTypes.CAMERA_UPDATE,
            params: [],
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    public changeViewpoint(viewpoint: string) {
        this.viewpoint = viewpoint;
        this.configuratorService.sendMessage({
            action: MessageTypes.CAMERA_SET_VIEWPOINT,
            params: [viewpoint],
        });
    }

    public hoverViewpoint(viewpoint: string) {
        this.viewpointHover = viewpoint;
    }

}
