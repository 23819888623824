import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {IOrder} from '../../interfaces/order';

@Component({
    selector: 'app-modal-order-confirm',
    templateUrl: './modal-order-confirm.component.html',
    styleUrls: ['./modal-order-confirm.component.scss']
})
export class ModalOrderConfirmComponent implements OnInit {

    public modalData: any;
    public order: IOrder;

    constructor(
        private modalService: ModalService,
    ) {
    }

    ngOnInit() {
        this.modalData = this.modalService.getData().data;
    }

    public confirm() {
        const parent = this.modalData.parent;
        parent.placeOrder();
        this.modalService.close();
    }
}
