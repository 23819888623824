import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {RouteUtil} from '../utils';
import {AuthenticationService} from '../services/authentication-service';

@Injectable({
    providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let canActivate = true;
        if (this.authenticationService.isAuthenticated()) {
            const redirectUrl = RouteUtil.getAuthRedirectUrl(route);
            this.router.navigate([redirectUrl]);
            canActivate = false;
        }
        return canActivate;
    }

}
