import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-information-button',
    templateUrl: './information-button.component.html',
    styleUrls: ['./information-button.scss']
})
export class InformationButtonComponent {

    constructor(private translateService: TranslateService) {
    }

    public navigateToInfoPage() {
        const infoUrl = 'https://www.confibuildinfo.com';
        window.open(infoUrl, '_blank');
    }
}
