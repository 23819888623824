import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatId'
})
export class FormatIdPipe implements PipeTransform {

    transform(value: number): string {
        return value.toString().padStart(6, '0');
    }

}
