import {Component, OnInit, OnDestroy, Injectable} from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
    CanActivate,
    ActivatedRouteSnapshot,
    Router,
    UrlTree
} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {SnapshotManagerService} from '../../services/snapshot-manager/snapshot-manager.service';
import {ModalService} from '../../services/modal-service';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {ModalFinishComponent} from '../../components/modal-finish/modal-finish.component';
import {ModalAddVersionComponent} from '../../components/modal-add-version/modal-add-version.component';
import {AuthRoles} from '../../enums';
import {AuthenticationService} from '../../services/authentication-service';

@Component({
    selector: 'app-page-steps-component',
    templateUrl: './page-steps.component.html',
    styleUrls: ['./page-steps.component.scss']
})
export class PageStepsComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    public step: number;
    public initialized = false;

    constructor(
        private route: ActivatedRoute,
        private modalService: ModalService,
        private snapshotManager: SnapshotManagerService,
        private authenticationService: AuthenticationService,
        private configInterface: ConfiguratorInterfaceService,
    ) {
    }

    ngOnInit() {
        this.step = parseInt(this.route.snapshot.paramMap.get('step'), 10);
        this.subscriptions.push(this.route.paramMap.subscribe((paramMap: ParamMap) => {
            this.step = parseInt(paramMap.get('step'), 10);
        }));

        // Make sure to enable the edit mode in case the dealer is editing the configuration
        // From the offer uuid screen ( SNOEI-386 )
        this.configInterface.viewerReady().then(() => {
            this.configInterface.sendMessage({
                action: 'setEditMode',
                params: [true],
            });
        });
        this.subscriptions.push(this.configInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'configurator') {
                this.initialized = data.initialized;
            }
        }));
    }

    public canViewPrices(): boolean {
        return this.authenticationService.userHasRole([AuthRoles.DEALER])
            || this.authenticationService.userHasRole([AuthRoles.ADMIN]);
    }

    public finish() {

        // Update order
        if (this.route.snapshot.paramMap.get('uuid') !== null) {
            this.modalService.open({
                component: ModalAddVersionComponent,
                closeable: true,
            });

        // Finish and create new offer
        } else {
            this.modalService.open({
                component: ModalFinishComponent,
                closeable: true,
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}

@Injectable()
export class RouteGuardPageStep implements CanActivate {

    constructor(
        private router: Router
    ) {}

    // Route guard when entering
    canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
        const step = parseInt(route.params.step, 10);
        if (step < 1 || step > 6) {
            return this.router.parseUrl('/step/1');
        }
        return true;
    }

}
