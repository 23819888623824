import {Injectable} from '@angular/core';
import {ConfiguratorInterfaceService} from '../configurator-interface/configurator-interface.service';
import {StatePersistorService} from '../state-persistor/state-persistor.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {IOrderConfigurationData} from '../../interfaces/order';
import {Subject} from 'rxjs/internal/Subject';
import {ColorService} from '../color-service/color-service.service';

/**
 * Class for managing the snapshot logic that is used to communicate with the configurator
 *
 * This class keeps a record of all the send messages per step.
 */
@Injectable()
export class SnapshotManagerService {

    private stateCanRedo = false;
    private stateCanUndo = false;

    private initialState: any;
    private initialStateLoaded = false;
    private loadedNewBuilding = false;
    private loadCustomConfigurationData = false;

    private configuratorEventListener: Subscription;
    private configuratorInterface: ConfiguratorInterfaceService;
    private statePersistorService: StatePersistorService;
    private debounceClearAll: Subject<any>;

    constructor(
        configuratorInterface: ConfiguratorInterfaceService,
        statePersistorService: StatePersistorService,
        private colorService: ColorService,
    ) {
        this.configuratorInterface = configuratorInterface;
        this.statePersistorService = statePersistorService;
        this.debounceClearAll = new Subject();

        this.configuratorEventListener = this.configuratorInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'configurator' && data.initialized && !this.loadCustomConfigurationData) {

                // Use the JSON from the local storage if it is available
                if (this.statePersistorService.hasInitialState()) {
                    let initialState = this.statePersistorService.getInitialState();

                    // SNOEI-686 - Live migration of RAL hex colors
                    initialState = this.colorService.migrateConfigurationHexColors(initialState);

                    this.configuratorInterface.sendMessage({
                        action: 'loadTemplate',
                        params: [initialState],
                    });
                } else {
                    // Load a Romney by default if we dont have any local storage state
                    const defaultColors = this.colorService.getDefaultColors();

                    this.configuratorInterface.sendMessage({
                        action: 'createDemoBuilding',
                        params: ['variant', defaultColors],
                    });
                }
            }
            if (data.action === 'loadTemplate') {
                this.loadedNewBuilding = true;
                this.initialStateLoaded = true;
            }
            if (data.action === 'stateChanged') { // received from config when the JSON has been changed.
                if (this.loadedNewBuilding && this.initialStateLoaded) {
                    this.loadedNewBuilding = false;
                } else {
                    this.initialStateLoaded = false;
                }

                // Custom configuration is loaded
                if (this.loadCustomConfigurationData) {
                    this.loadCustomConfigurationData = false;
                }

                statePersistorService.persistState({
                    template: data.template,
                    buildingInfo: data.buildingInfo,
                    addedData: data.addedData,
                });
            }
            if (data.action === 'UndoRedoState') {
                this.setUndoRedoState(data);
            }
        });
    }

    /**
     * Set the undo redo states
     */
    public setUndoRedoState(state: { current: number, builds: any[] }): void {
        if (!this.initialState) {
            this.initialState = state.builds[0];
        }

        this.stateCanUndo = state.current > 0;
        this.stateCanRedo = state.builds.length - 1 > state.current;
    }

    /**
     * Send an undo message to the configurator if eligable
     */
    public undo(): void {
        if (this.canUndo()) {
            this.configuratorInterface.sendMessage({
                action: 'undo',
                params: []
            });
        }
    }

    /**
     * Check whether or not we can do an undo action
     */
    public canUndo(): boolean {
        return this.stateCanUndo;
    }

    /**
     * Send a redo message to the configurator if eligible
     */
    public redo(): void {
        if (this.canRedo()) {
            this.configuratorInterface.sendMessage({
                action: 'redo',
                params: []
            });
        }
    }

    /**
     * Check whether or not we can do a redo action
     */
    public canRedo(): boolean {
        return this.stateCanRedo;
    }

    /**
     * Clear the state history
     */
    public clearSnapshotMessages(): void {
        this.configuratorInterface.sendMessage({
            action: 'clearUndoRedo',
            params: []
        });
    }

    /**
     * SNOEI-681 - Remove stored configuration and reload the page to load 3d/configuration defaults
     */
    public reloadWithDefaults(): void {
        this.configuratorEventListener.unsubscribe();
        this.statePersistorService.removeStateFromStorage();
        window.location.reload();
    }

    public loadConfigurationData(configurationData: IOrderConfigurationData): void {
        this.loadCustomConfigurationData = true;

        // SNOEI-686 - Live migration of RAL hex colors
        configurationData = this.colorService.migrateConfigurationHexColors(configurationData);

        this.statePersistorService.setInitialState(configurationData);
        this.statePersistorService.persistState(configurationData);

        this.configuratorInterface.viewerReady().then(() => {
            this.configuratorInterface.sendMessage({
                action: 'loadTemplate',
                params: [configurationData],
            });
        });
    }

}
