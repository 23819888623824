import {Component} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {OrderService} from '../../services/order-service/order-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {OrderConfigurationDataMapper} from '../../services/order-configuration-data-mapper/order-configuration-data-mapper';
import {StatePersistorService} from '../../services/state-persistor/state-persistor.service';

@Component({
    selector: 'app-modal-add-version',
    templateUrl: './modal-add-version.component.html',
    styleUrls: ['./modal-add-version.component.scss']
})
export class ModalAddVersionComponent {

    constructor(
        private modalService: ModalService,
        private orderService: OrderService,
        private orderConfigurationMapperService: OrderConfigurationDataMapper,
        private statePersistorService: StatePersistorService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public savingForm = false;
    public serverError = false;
    private configuratorEventListener: Subscription;
    private configuration: any;

    public finish() {
        this.savingForm = true;
        const orderConfiguration = this.orderService.getCurrentOrderConfiguration();

        this.orderService.createOrderVersion(orderConfiguration.order_id, {
            configuration: this.orderConfigurationMapperService.forPost(this.statePersistorService.retrieveState()),
        }).then(() => {
            this.savingForm = false;
            this.serverError = false;
            this.router.navigate(['/dealer/offer/' + orderConfiguration.order_id]).then( () => {
                this.close();
            });
        }).catch((error) => {
            this.savingForm = false;
            this.serverError = true;
            console.error(error);
        });
    }

    public close() {
        this.savingForm = false;
        this.modalService.close();
    }

}
