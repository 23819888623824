import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../services/modal-service';
import {ModalOfferRequestComponent} from '../modal-offer-request/modal-offer-request.component';

@Component({
    selector: 'app-download-button',
    templateUrl: './download-button.component.html',
    styleUrls: ['./download-button.scss']
})
export class DownloadButtonComponent {

    constructor(private translateService: TranslateService, private modalService: ModalService) {
    }

    public generatePDF() {
        this.modalService.open({
            component: ModalOfferRequestComponent,
            closeable: true,
            data: {},
        });
    }
}
