import {Injectable} from '@angular/core';
import {IDealerInformation, IDealerPrivateInformation} from '../../interfaces/information';
import {ApiService} from '../api-service/api-service.service';
import {LocalStorage} from 'ngx-webstorage';
import {AuthenticationService} from '../authentication-service';
import {TranslateService} from '@ngx-translate/core';

const defaultDealerInfo: IDealerInformation = {
    name: 'J.Snoei BV',
    address: 'Van der Giessenweg 6' + '\n' + '2921 LP Krimpen aan den IJssel' + '\n' + '+31 (0) 180 519 155',
    phone: '+31 (0) 180 519 155',
    email_address: 'info@jsnoeibv.com',
    locale: 'en'
};

/**
 * Class for updating and retrieving info about the domain and dealer
 */
@Injectable({
    providedIn: 'root'
})
export class InfoService {

    @LocalStorage('affiliateId', '') private affiliateId;
    @LocalStorage('dealerInfo', defaultDealerInfo) private dealerInfo: IDealerInformation;

    constructor(
        private apiService: ApiService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
    ) {

        // Listen to authentication changes
        this.authenticationService.onChanged().subscribe((authenticated) => {
            if (authenticated) {
                const authInfo = this.authenticationService.getAuthInfo();
                this.setAffiliateId(authInfo.affiliateId || '');
            }
        });

    }

    /**
     * Initialize the service
     */
    public init(): Promise<IDealerInformation> {
        return this.getDealerInfo();
    }

    /**
     * Get the dealer affiliate id
     */
    public getAffiliateId(): string {
        return this.affiliateId;
    }

    /**
     * Set the dealer affiliate id
     */
    public setAffiliateId(affiliateId: string): void {
        if (this.affiliateId !== affiliateId) {
            this.affiliateId = affiliateId;
        }
        this.getDealerInfo().then((info) => {
            if (info.locale && this.translateService.currentLang !== info.locale) {
                this.translateService.use(info.locale);
            }
        });
    }

    /**
     * Get the dealer private info
     */
    public getDealerPrivateInfo(): Promise<IDealerPrivateInformation> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/dealer/info?affiliate=${this.getAffiliateId()}`).then((response) => {
                if (response.body.affiliate_link) {
                    this.affiliateId = response.body.affiliate_link;
                }
                resolve(response.body);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getCachedDealerInfo(): IDealerInformation {
        return this.dealerInfo;
    }

    /**
     * Get the dealer info
     */
    public getDealerInfo(): Promise<IDealerInformation> {
        if (this.getAffiliateId() === '') {
            this.dealerInfo = defaultDealerInfo;
            return Promise.resolve(this.dealerInfo);
        } else {
            return new Promise((resolve, reject) => {
                this.apiService.get(`/info?affiliate=${this.getAffiliateId()}`).then((response) => {
                    this.dealerInfo = response.body;
                    if (this.dealerInfo.affiliate_link) {
                        this.affiliateId = this.dealerInfo.affiliate_link;
                    }
                    resolve(response.body);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }

    /**
     * Save the dealer info
     */
    public saveDealerInfo(dealerInfo: IDealerInformation): Promise<any> {

        const body = {
            // TODO SNOEI-308 dealer model emailAddress -> email_address
            emailAddress: dealerInfo.email_address,
            ...dealerInfo,
        };

        return this.apiService.post(`/dealer/info?affiliate=${this.getAffiliateId()}`, body).then(() => {
            // Update after a successful post
            this.getDealerInfo();
        });
    }

}
