<div class="modal-title">{{ 'configurator:modal:blueprint-request:title' | translate }}</div>
<div class="modal-template">
    <p>{{ 'configurator:modal:blueprint-request:disclaimer' | translate }}</p>
    <img class="blueprint-preview" src="../../assets/images/preview-example.png" />
</div>
<div class="modal-footer">
    <div class="text-error" *ngIf="serverError">
        {{ 'configurator:modal:blueprint-request:server_error' | translate }}
    </div>
    <button (click)="finish()" class="btn-primary btn-block btn-large" [attr.disabled]="!isFormValid() || savingForm ? 'disabled' : null">
        <div *ngIf="savingForm" class="loading-spinner-inline"></div>
        <ng-container *ngIf="savingForm">
            {{ 'configurator:modal:blueprint-request:button' | translate }}
        </ng-container>
        <ng-container *ngIf="!savingForm">
            {{ 'configurator:modal:blueprint-request:button_show' | translate }}
        </ng-container>
    </button>
</div>
