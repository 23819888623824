import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';

@Component({
    selector: 'app-step-building-load-component',
    templateUrl: './step-building-load.component.html',
    styleUrls: ['./step-building-load.component.scss']
})
export class StepBuildingLoadComponent implements OnInit, OnDestroy {
    configuratorEventListener: Subscription;

    public load = null;

    constructor(private configInterface: ConfiguratorInterfaceService) {
        this.configuratorEventListener = this.configInterface.receiveMessage().subscribe((data) => {
            if ( data.action === 'stateChanged') {
                if (data.addedData.hasOwnProperty('load')) {
                    this.load = data.addedData.load;
                }
            }
        });
    }

    ngOnInit() {
        this.configInterface.requestState();
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    loadChanged(loadType: string, value: number | string) {
        this.load[loadType].value = this.load[loadType].steps[value];

        this.configInterface.sendMessage({
            action: 'addDataToBuildingConfig',
            params: [{load: this.load}],
        });
    }
}
