import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {

    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() steps: number[];
    @Input() direction: string;
    @Output() valueChanged = new EventEmitter<number | string>();

    public inputValue: number;
    public shownValue: number;
    public inputMin: number;
    public inputMax: number;
    public inputStep: number;

    constructor() {
    }

    @Input()
    set value(value) {
        this.shownValue = this.direction === 'depth' ? value : this.roundNumber(value);
        this.inputValue = value;
    }

    ngOnInit() {
        this.inputMin = this.min || 0;
        this.inputMax = this.max || 100;
        this.inputStep = this.step || 0.1;
    }

    public changedValue(button?: string) {
        if (this.steps && button) { // if we have steps we do something else.
            this.changedValueWithSteps(button);
        } else {
            if (button) {
                if (button === '-') {
                    this.shownValue -= this.inputStep;
                }
                if (button === '+') {
                    this.shownValue += this.inputStep;
                }
            }

            if (this.shownValue < this.inputMin) {
                this.shownValue = this.inputMin;
            }
            if (this.shownValue > this.inputMax) {
                this.shownValue = this.inputMax;
            }

            // Round the value with a maximum of 1 decimal
            this.inputValue = Math.round(this.shownValue * 10) / 10;

            if (!Number.isInteger(this.inputValue / this.inputStep)) {
                this.inputValue = Math.min(100, Math.round(this.inputValue / this.inputStep) * this.inputStep);
            }
        }

        this.shownValue = this.inputValue;

        this.valueChanged.emit(this.inputValue);
    }

    private changedValueWithSteps(button?: string) {
        if (button) {
            const currentIndex = this.steps.findIndex((step) => {
                return Number(step) === Number(this.inputValue);
            });

            if (button === '-') {
                this.inputValue = this.steps[ Math.max( 0, currentIndex - 1 ) ];
            }

            if (button === '+') {
                this.inputValue = this.steps[Math.min( this.steps.length - 1, currentIndex + 1 )];
            }
        }
    }

    private roundNumber(number: number) {
        return Math.round(number);
    }
}
