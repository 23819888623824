import {Component} from '@angular/core';
import {SnapshotManagerService} from '../../services/snapshot-manager/snapshot-manager.service';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {ModalService} from '../../services/modal-service';
import {ModalResetButtonComponent} from '../modal-reset-button/modal-reset-button.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-reset-button',
    templateUrl: './reset-button.component.html',
    styleUrls: ['./reset-button.scss']
})
export class ResetButtonComponent {

    public resetButtonEnabled = false;

    constructor(
        private snapshotManager: SnapshotManagerService,
        private modalService: ModalService,
        private configuratorInterface: ConfiguratorInterfaceService,
        private translateService: TranslateService,
    ) {
    }

    public resetAll(): void {
        this.translateService.get('configurator:modal:reset:title-short').subscribe((translation: string) => {
            this.modalService.open({
                title: translation,
                component: ModalResetButtonComponent,
                closeable: true,
                customClass: 'custom-modal-prompt',
            });
        });
    }

}
