<app-header-component>
    <ng-container account>
        <app-account-button></app-account-button>
    </ng-container>
</app-header-component>
<main class="page-dealer-main">
    <aside class="page-dealer-main__aside">
        <app-dealer-menu></app-dealer-menu>
    </aside>
    <section class="page-dealer-main__section">
        <div class="padded">
            <h1>{{ 'dealer:settings:title' | translate }}</h1>
        </div>
        <div class="padded">
            <app-box>
                <ng-container *ngIf="dealerSettingsFormIsUpdating">
                    <app-loading-spinner>{{ 'dealer:settings:form:message:loading' | translate }}</app-loading-spinner>
                </ng-container>
                <ng-container *ngIf="!dealerSettingsFormIsUpdating">

                    <div class="affiliate-link">
                        <div class="affiliate-link__label">{{ 'dealer:settings:form:affiliatelink:label' | translate }}:</div>
                        <div>{{ affiliateLink }}</div>
                        <button class="btn btn-outline btn-small affiliate-link__button" (click)="copyAffiliateLink()"><i class="icon">clipboard</i> {{ 'dealer:settings:form:affiliatelink:copy' | translate }}</button>
                    </div>
                    <hr>

                    <form [formGroup]="dealerSettingsForm" [ngClass]="{'form-submit-error': dealerSettingsFormHasError}">
                        <div class="form-group">
                            <label class="form-label">{{ 'dealer:settings:form:logo:label' | translate }}</label>
                            <div class="input-group">
                                <input type="hidden" formControlName="logo">
                                <app-image-add
                                    [image]="dealerSettingsForm.value.logo"
                                    [thumbWidth]="300"
                                    [thumbHeight]="100"
                                    [imageCrop]="false"
                                    [imageType]="png"
                                    [previewWidth]="300"
                                    [previewHeight]="100"
                                    (thumbGenerated)="logoGenerated($event)"
                                    [ngClass]="{'disabled': dealerSettingsForm.disabled}"
                                ></app-image-add>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{ 'dealer:settings:form:address:label' | translate }}</label>
                            <textarea class="form-control" [ngClass]="{'is-invalid': dealerSettingsFormHasError || !isFormFieldValid('address')}" formControlName="address"></textarea>
                        </div>
                        <div class="form-group required">
                            <label class="form-label">{{ 'dealer:settings:form:email:label' | translate }}</label>
                            <input type="text" class="form-control" [ngClass]="{'is-invalid': dealerSettingsFormHasError || !isFormFieldValid('email_address')}" formControlName="email_address">
                            <div class="invalid-feedback" *ngIf="getFormFieldError('email_address') === 'required'">{{ 'dealer:settings:form:field:required' | translate }}</div>
                            <div class="invalid-feedback" *ngIf="getFormFieldError('email_address') === 'invalidEmail'">{{ 'dealer:settings:form:field:invalid:email' | translate }}</div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{ 'dealer:settings:form:phone:label' | translate }}</label>
                            <input type="text" class="form-control" [ngClass]="{'is-invalid': dealerSettingsFormHasError || !isFormFieldValid('phone')}" formControlName="phone">
                        </div>
                        <hr>
                        <div class="form-group">
                            <label class="form-label">{{ 'dealer:settings:form:offermargin:label' | translate }}</label>
                            <div class="input-group">
                                <input type="text" class="form-control form-control-tiny" [ngClass]="{'is-invalid': dealerSettingsFormHasError || !isFormFieldValid('margin')}" formControlName="margin" appNumberOnly>
                                <div class="input-group-append">
                                    <div class="input-group-text">%</div>
                                </div>
                                <div class="input-group-note">{{ 'dealer:settings:form:offermargin:note' | translate }}</div>
                            </div>
                        </div>
                        <hr>
                        <button
                            class="btn primary"
                            (click)="submitDealerSettingsForm()"
                            [attr.disabled]="!isFormValid() ? 'disabled' : null"
                        >
                            <div *ngIf="dealerSettingsFormSubmitting" class="loading-spinner-inline"></div>
                            {{ 'dealer:settings:form:button:save' | translate }}
                        </button>
                    </form>
                </ng-container>
            </app-box>
        </div>
    </section>
</main>
