import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';

@Component({
    selector: 'app-step-building-coating-component',
    templateUrl: './step-building-coating.component.html',
    styleUrls: ['./step-building-coating.component.scss']
})
export class StepBuildingCoatingComponent implements OnInit, OnDestroy {
    configuratorEventListener: Subscription;
    public coating; // set initial values;

    constructor(private configInterface: ConfiguratorInterfaceService) {
        this.configuratorEventListener = this.configInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'stateChanged') {
                if (data.addedData.hasOwnProperty('coating')) {
                    this.coating = data.addedData.coating;
                }
            }
        });
    }

    ngOnInit() {
        this.configInterface.viewerReady().then(() => {
            this.configInterface.requestState();
        });
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    coatingChanged(type, value) {
        switch (type) {
            case 'antiCondensationFilter':
                this.coating[type] = value === 'configurator:choice:yes';
                break;
            case 'wallIsolation':
            case 'roofIsolation':
                this.coating[type].value = this.coating[type].steps[value];
                break;
            default:
                this.coating[type] = value;
                break;
        }
        this.updateCoating();
    }

    private updateCoating() {
        this.configInterface.sendMessage({
            action: 'addDataToBuildingConfig',
            params: [{coating: this.coating}],
        });
    }
}
