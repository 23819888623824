import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal-service';
import {IOrderLine} from '../../interfaces/order-line';
import {FormValidation} from '../../classes';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtil} from '../../utils';

@Component({
    selector: 'app-modal-add-order-line',
    templateUrl: './modal-order-line.component.html',
    styleUrls: ['./modal-order-line.component.scss']
})
export class ModalOrderLineComponent extends FormValidation implements OnInit {

    public modalData: any;
    public orderLine: IOrderLine;
    public offerOrderLineForm: UntypedFormGroup;

    constructor(
        private modalService: ModalService,
    ) {
        super();
    }

    ngOnInit() {
        this.modalData = this.modalService.getData().data;
        this.orderLine = this.modalData.orderLine;

        // Create the reactive form
        this.offerOrderLineForm = new UntypedFormGroup({
            title: new UntypedFormControl('', [Validators.required]),
            price: new UntypedFormControl('', [Validators.required, FormUtil.numberValidator]),
        });

        // Assign the reactive form for validation
        this.assignFormValidation([this.offerOrderLineForm]);

        // Fill the form with the cached data
        this.offerOrderLineForm.patchValue({
            title: `${this.orderLine.title}`,
            price: `${FormUtil.convertEurocentsToEuro(this.orderLine.price)}`,
        });
    }

    public saveOrderLine(): void {
        const parent = this.modalData.parent;

        // Update the order line with the new values
        this.orderLine.title = this.offerOrderLineForm.value.title;
        this.orderLine.price = FormUtil.convertEuroToEurocents(this.offerOrderLineForm.value.price);

        if (this.orderLine.id) {
            parent.saveOrderLine(this.orderLine);
        } else {
            parent.addAndSaveOrderLine(this.orderLine);
        }
        this.modalService.close();
    }

}
