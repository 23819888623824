import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PageStepsComponent, RouteGuardPageStep} from '../pages/steps/page-steps.component';
import {PageLoginComponent} from '../pages/login/page-login.component';
import {PageDealerOffersComponent} from '../pages/dealer-offers/page-dealer-offers.component';
import {PageDealerOfferComponent} from '../pages/dealer-offer/page-dealer-offer.component';
import {PageDealerSettingsComponent} from '../pages/dealer-settings/page-dealer-settings.component';
import {PageOfferSendComponent} from '../pages/offer-send/page-offer-send.component';
import {PageOfferOverviewComponent} from '../pages/offer-overview/page-offer-overview.component';
import {PageSundayComponent} from '../pages/sunday/page-sunday.component';

import {
    AffiliateIdGuard,
    AuthRedirectGuard,
    AuthGuard,
    OrderUuidGuard,
    SundayGuard
} from '../guards';

import {
    AuthRoles,
} from '../enums';
import {PageResetComponent} from '../pages/reset/page-reset.component';

const routes: Routes = [{
    path: '',
    redirectTo: '/step/1',
    pathMatch: 'full',
}, {
    path: 'step/:step',
    component: PageStepsComponent,
    canActivate: [RouteGuardPageStep, SundayGuard]
}, {
    path: 'send',
    component: PageOfferSendComponent,
    canActivate: [SundayGuard]
}, {
    path: 'offer/:uuid',
    component: PageOfferOverviewComponent,
    canActivate: [OrderUuidGuard, SundayGuard]
}, {
    path: 'login',
    component: PageLoginComponent,
    canActivate: [AuthRedirectGuard],
    data: {
        authRedirect: ''
    }
}, {
    path: 'reset',
    component: PageResetComponent,
}, {
    path: 'sunday',
    component: PageSundayComponent,
}, {
    path: 'dealer/offers',
    component: PageDealerOffersComponent,
    canActivate: [AuthGuard],
    data: {
        authSpecificRoles: [
            AuthRoles.DEALER
        ]
    }
}, {
    path: 'dealer/offer/:offer',
    component: PageDealerOfferComponent,
    canActivate: [AuthGuard],
    data: {
        authSpecificRoles: [
            AuthRoles.DEALER
        ]
    }
}, {
    // Redirect incorrect offer detail route (mainly for sent emails)
    path: 'dealer/offers/:offer',
    redirectTo: '/dealer/offer/:offer'
}, {
    path: 'dealer/settings',
    component: PageDealerSettingsComponent,
    canActivate: [AuthGuard],
    data: {
        authSpecificRoles: [
            AuthRoles.DEALER
        ]
    }
}, {
    path: 'dealer/offer/:uuid/step/:step',
    component: PageStepsComponent,
    canActivate: [AuthGuard, OrderUuidGuard, RouteGuardPageStep],
    data: {
        authSpecificRoles: [
            AuthRoles.DEALER
        ]
    }
}, {
    path: 'affiliate/:affiliateId',
    canActivate: [AffiliateIdGuard],
    component: ({} as any)
}, {
    path: '**',
    redirectTo: '/step/1'
}];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
})],
    exports: [RouterModule],
    providers: [RouteGuardPageStep],
})
export class AppRoutingModule {
}
