<app-header-component>
    <ng-container account>
        <app-account-button></app-account-button>
    </ng-container>
</app-header-component>
<main class="page-dealer-main">
    <aside class="page-dealer-main__aside">
        <app-dealer-menu></app-dealer-menu>
    </aside>
    <section class="page-dealer-main__section">

        <div class="table">
            <div class="left">
                <h1>{{ 'dealer:offers:title' | translate }}</h1>
            </div>
            <div class="right">
                <form [formGroup]="searchForm">
                    <div class="input-group">
                        <input class="form-control" type="search" formControlName="query" (keydown.enter)="onKeydown($event)">
                    </div>
                </form>
            </div>
        </div>

        <div class="padded">
            <app-box>
                <div class="boxed-table-wrapper">
                    <table>
                        <thead>
                            <tr class="orderColumnheaders">
                                <th [ngClass]="selectedColumn === 'id' ? 'selected' : ''" width="90" (click)="selectColumn('id', orderBy)">
                                    {{ 'dealer:offers:column:id' | translate }}
                                    <span *ngIf="selectedColumn==='id'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th [ngClass]="selectedColumn === 'emailAddress' ? 'selected' : ''" (click)="selectColumn('emailAddress', orderBy)">
                                    {{ 'dealer:offers:column:customer' | translate }}
                                    <span *ngIf="selectedColumn==='emailAddress'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th [ngClass]="selectedColumn === 'comments' ? 'selected' : ''" (click)="selectColumn('comments', orderBy)" class="hide-1700">
                                    {{ 'dealer:offers:column:comments' | translate }}
                                    <span *ngIf="selectedColumn==='comments'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th [ngClass]="selectedColumn === 'status' ? 'selected' : ''" width="150" (click)="selectColumn('status', orderBy)">
                                    {{ 'dealer:offers:column:status' | translate }}
                                    <span *ngIf="selectedColumn==='status'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th [ngClass]="selectedColumn === 'totalDealerPrice' ? 'selected' : ''" width="150" (click)="selectColumn('totalDealerPrice', orderBy)">
                                    {{ 'dealer:offers:column:price' | translate }}
                                    <span *ngIf="selectedColumn==='totalDealerPrice'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th [ngClass]="selectedColumn === 'createdAt' ? 'selected' : ''" width="180" (click)="selectColumn('createdAt', orderBy)">
                                    {{ 'dealer:offers:column:created' | translate }}
                                    <span *ngIf="selectedColumn==='createdAt'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th [ngClass]="selectedColumn === 'updatedAt' ? 'selected' : ''" width="180" (click)="selectColumn('updatedAt', orderBy)" class="hide-1500">
                                    {{ 'dealer:offers:column:updated' | translate }}
                                    <span *ngIf="selectedColumn==='updatedAt'">
                                        <ng-container *ngIf="orderBy==='asc'">^</ng-container>
                                        <ng-container *ngIf="orderBy==='desc'">v</ng-container>
                                    </span>
                                </th>
                                <th width="80"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!loadingOrders && !loadingError && orders.length > 0">
                                <tr *ngFor="let order of orders" class="clickable" [routerLink]="['/dealer/offer', order.id]">
                                    <td>{{ order.id | formatId }}</td>
                                    <td><span class="highlight">{{ order.email_address }}</span></td>
                                    <td class="hide-1700"><small>{{ order.comments }}</small></td>
                                    <td [class]="'order-status-' + order.status">{{ 'dealer:order:status:' + order.status | translate }}</td>
                                    <td>{{ order.total_price | formatEuroCents }}</td>
                                    <td><small>{{ order.created_at | date:'dd-MM-yyyy' }} {{ 'dealer:order:date:at' | translate }} {{ order.created_at | date:'HH:mm' }}</small></td>
                                    <td class="hide-1500"><small>{{ order.updated_at | date:'dd-MM-yyyy' }} {{ 'dealer:order:date:at' | translate }} {{ order.updated_at | date:'HH:mm' }}</small></td>
                                    <td><i class="icon">arrowright</i></td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="!loadingOrders && !loadingError && orders.length === 0">
                                <td colspan="8">
                                    {{ 'dealer:offers:none' | translate }}
                                </td>
                            </tr>
                            <tr *ngIf="loadingError">
                                <td colspan="8">
                                    <i class="icon">alert</i>
                                    {{ 'dealer:offers:loading:error' | translate }}
                                </td>
                            </tr>
                            <tr *ngIf="loadingOrders">
                                <td colspan="8"><app-loading-spinner>{{ 'dealer:offers:loading:message' | translate }}</app-loading-spinner></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-box>
        </div>
    </section>
</main>
