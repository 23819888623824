import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {ToastrModule} from 'ngx-toastr';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {environment} from '../environments/environment';

// Components
import {AppComponent} from './app.component';
import {PageStepsComponent} from '../pages/steps/page-steps.component';
import {PageLoginComponent} from '../pages/login/page-login.component';
import {PageDealerOfferComponent} from '../pages/dealer-offer/page-dealer-offer.component';
import {PageDealerOffersComponent} from '../pages/dealer-offers/page-dealer-offers.component';
import {PageDealerSettingsComponent} from '../pages/dealer-settings/page-dealer-settings.component';
import {PageOfferSendComponent} from '../pages/offer-send/page-offer-send.component';
import {PageOfferOverviewComponent} from '../pages/offer-overview/page-offer-overview.component';
import {PageSundayComponent} from '../pages/sunday/page-sunday.component';
import {HeaderComponent} from '../components/header/header.component';
import {ThreeDViewerComponent} from '../components/three-d-viewer/three-d-viewer.component';
import {SidebarHeaderComponent} from '../components/sidebar-header/sidebar-header.component';
import {StepBuildingTypeComponent} from '../components/step-building-type/step-building-type.component';
import {StepBuildingMeasurementsComponent} from '../components/step-building-measurements/step-building-measurements.component';
import {StepBuildingLoadComponent} from '../components/step-building-load/step-building-load.component';
import {StepBuildingCoatingComponent} from '../components/step-building-coating/step-building-coating.component';
import {StepBuildingInsetsComponent} from '../components/step-building-insets/step-building-insets.component';
import {StepBuildingColouringComponent} from '../components/step-building-colouring/step-building-colouring.component';
import {TooltipComponent} from '../components/tooltip/tooltip.component';
import {ModalComponent} from '../components/modal/modal.component';
import {InputDecimalComponent} from '../components/input-decimal/input-decimal.component';
import {InputNumberComponent} from '../components/input-number/input-number.component';
import {InputSliderComponent} from '../components/input-slider/input-slider.component';
import {InputButtonImageBigComponent} from '../components/input-button-image-big/input-button-image-big.component';
import {InputButtonToggleComponent} from '../components/input-button-toggle/input-button-toggle.component';
import {InputButtonDimensionToggleComponent} from '../components/input-button-dimension-toggle/input-button-dimension-toggle.component';
import {InputColourButtonsComponent} from '../components/input-colour-buttons/input-colour-buttons.component';
import {ToastComponent} from '../components/toast/toast.component';
import {NavigationComponent} from '../components/navigation/navigation.component';
import {UnredoButtonsComponent} from '../components/unredo-buttons/unredo-buttons.component';
import {ResetButtonComponent} from '../components/reset-button/reset-button.component';
import {InformationButtonComponent} from '../components/information-button/information-button.component';
import {SidebarPropertyComponent} from '../components/sidebar-property/sidebar-property.component';
import {ModalResetButtonComponent} from '../components/modal-reset-button/modal-reset-button.component';
import {LoadingSpinnerComponent} from '../components/loading-spinner/loading-spinner.component';
import {ModalFinishComponent} from '../components/modal-finish/modal-finish.component';
import {PriceBarComponent} from '../components/price-bar/price-bar.component';
import {AccountButtonComponent} from '../components/account-button/account-button.component';
import {DealerMenuComponent} from '../components/dealer-menu/dealer-menu.component';
import {ImageAddComponent} from '../components/image-add/image-add.component';
import {ModalOrderLineComponent} from '../components/modal-order-line/modal-order-line.component';
import {ModalOrderConfirmComponent} from '../components/modal-order-confirm/modal-order-confirm.component';
import {OrderVersionInfoComponent} from '../components/order-version-info/order-version-info.component';
import {AccordionComponent} from '../components/accordion/accordion.component';
import {ListComponent} from '../components/list/list.component';
import {LoadingSpinnerFullscreenComponent} from '../components/loading-spinner-fullscreen/loading-spinner-fullscreen.component';
import {ColorComponent} from '../components/color/color.component';
import {BoxComponent} from '../components/box/box.component';
import {ModalAddVersionComponent} from '../components/modal-add-version/modal-add-version.component';
import {ViewpointComponent} from '../components/viewpoint/viewpoint.component';
import {SidebarContentComponent} from '../components/sidebar-content/sidebar-content.component';
import {DownloadButtonComponent} from '../components/download-pdf-button/download-button.component';
import {ModalBlueprintRequestComponent} from '../components/modal-blueprint-request/modal-blueprint-request.component';
import {ModalOfferRequestComponent} from '../components/modal-offer-request/modal-offer-request.component';

// Services
import {ConfiguratorInterfaceService} from '../services/configurator-interface/configurator-interface.service';
import {ApiService} from '../services/api-service/api-service.service';
import {PriceCalculatorService} from '../services/price-calculator/price-calculator.service';
import {OrderService} from '../services/order-service/order-service.service';
import {SnapshotManagerService} from '../services/snapshot-manager/snapshot-manager.service';
import {StatePersistorService} from '../services/state-persistor/state-persistor.service';
import {NotificationService} from '../services/notification-service';
import {ModalService} from '../services/modal-service';
import {InfoService} from '../services/info-service/info-service.service';
import {ColorService} from '../services/color-service/color-service.service';
import {AuthenticationService} from '../services/authentication-service';
import {AccordionService} from '../services/accordion.service';
import {LoadingSpinnerService} from '../services/loading-spinner.service';
import {OrderConfigurationDataMapper} from '../services/order-configuration-data-mapper/order-configuration-data-mapper';
import {AnalyticsService} from '../services/analytics/analytics.service';
import {PdfContentService} from '../services/pdf-content-service';

// Directives
import {
    NumberOnlyDirective,
    FormatNumberDirective,
    FormatEuroCentsDirective
} from '../directives';

// Interceptors
import {JwtTokenInterceptor} from '../interceptors';

// Pipes
import {FormatNumberPipe} from '../pipes/format-number.pipe';
import {FormatIdPipe} from '../pipes/format-id.pipe';
import {ConvertEurocentToEuroPipe} from '../pipes/convert-eurocent-to-euro';
import {ConvertMillimeterToMeterPipe} from '../pipes/convert-millimeter-to-meter';
import {FormatEuroCentsPipe} from '../pipes/format-euro-cents.pipe';
import {FormatDecimalPipe} from '../pipes/format-decimal';
import {PageResetComponent} from '../pages/reset/page-reset.component';
import {InputCheckboxComponent} from '../components/input-checkbox/input-checkbox.component';
import {DatePipe} from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.apiUrl + '/translations/', '');
}

function ColorLoaderFactory(provider: ColorService) {
    return async () => {
        await provider.resolveColors();
    };
}

@NgModule({
    declarations: [
        AppComponent,
        PageStepsComponent,
        PageLoginComponent,
        PageDealerOfferComponent,
        PageDealerOffersComponent,
        PageDealerSettingsComponent,
        PageOfferSendComponent,
        PageOfferOverviewComponent,
        PageResetComponent,
        PageSundayComponent,
        HeaderComponent,
        ThreeDViewerComponent,
        SidebarHeaderComponent,
        StepBuildingTypeComponent,
        StepBuildingMeasurementsComponent,
        StepBuildingLoadComponent,
        StepBuildingCoatingComponent,
        StepBuildingInsetsComponent,
        StepBuildingColouringComponent,
        TooltipComponent,
        ModalComponent,
        InputDecimalComponent,
        InputNumberComponent,
        InputSliderComponent,
        InputButtonImageBigComponent,
        InputButtonToggleComponent,
        InputButtonDimensionToggleComponent,
        InputColourButtonsComponent,
        ToastComponent,
        AccordionComponent,
        NavigationComponent,
        UnredoButtonsComponent,
        ResetButtonComponent,
        InformationButtonComponent,
        SidebarPropertyComponent,
        ModalResetButtonComponent,
        ModalFinishComponent,
        PriceBarComponent,
        AccountButtonComponent,
        LoadingSpinnerComponent,
        DealerMenuComponent,
        BoxComponent,
        ImageAddComponent,
        ModalOrderLineComponent,
        OrderVersionInfoComponent,
        ModalOrderConfirmComponent,
        ListComponent,
        OrderVersionInfoComponent,
        ModalOrderConfirmComponent,
        LoadingSpinnerFullscreenComponent,
        ColorComponent,
        OrderVersionInfoComponent,
        ModalOrderConfirmComponent,
        LoadingSpinnerFullscreenComponent,
        NumberOnlyDirective,
        FormatEuroCentsDirective,
        FormatEuroCentsPipe,
        FormatDecimalPipe,
        FormatNumberDirective,
        FormatNumberPipe,
        FormatIdPipe,
        ConvertEurocentToEuroPipe,
        ConvertMillimeterToMeterPipe,
        ModalAddVersionComponent,
        ViewpointComponent,
        InputCheckboxComponent,
        ModalBlueprintRequestComponent,
        ModalOfferRequestComponent,
        SidebarContentComponent,
        DownloadButtonComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        NgxSmartModalModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxWebstorageModule.forRoot(),
    ],
    providers: [
        ConfiguratorInterfaceService,
        ApiService,
        OrderService,
        PriceCalculatorService,
        StatePersistorService,
        SnapshotManagerService,
        NotificationService,
        ModalService,
        AuthenticationService,
        InfoService,
        ColorService,
        AccordionService,
        LoadingSpinnerService,
        OrderConfigurationDataMapper,
        AccordionService,
        DatePipe,
        PdfContentService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtTokenInterceptor,
            multi: true,
        },
        AnalyticsService,
        {
            provide: APP_INITIALIZER,
            useFactory: ColorLoaderFactory,
            deps: [ColorService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(
        private analyticsService: AnalyticsService,
    ) {
        this.analyticsService.init();
    }

}
