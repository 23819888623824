<app-header-component class="elevated steps">
    <ng-container content>
        <app-navigation-component [step]="step" (finish)="finish()"></app-navigation-component>
    </ng-container>
    <ng-container account>
        <app-price-bar *ngIf="canViewPrices()"></app-price-bar>
        <app-download-button></app-download-button>
        <app-information-button></app-information-button>
        <app-reset-button></app-reset-button>
        <app-account-button></app-account-button>
    </ng-container>
</app-header-component>
<main>
    <section>
        <app-viewpoint></app-viewpoint>
        <app-order-version-info (addVersion)="finish()"></app-order-version-info>
        <app-three-d-viewer-component></app-three-d-viewer-component>
    </section>
    <aside>
        <ng-container *ngIf="initialized">
            <!-- Step 1 -->
            <ng-container *ngIf="step === 1">
                <app-step-building-type-component></app-step-building-type-component>
            </ng-container>

            <!-- Step 2 -->
            <ng-container *ngIf="step === 2">
                <app-step-building-measurements-component></app-step-building-measurements-component>
            </ng-container>

            <!-- Step 3 -->
            <ng-container *ngIf="step === 3">
                <app-step-building-load-component></app-step-building-load-component>
            </ng-container>

            <!-- Step 4 -->
            <ng-container *ngIf="step === 4">
                <app-step-building-coating-component></app-step-building-coating-component>
            </ng-container>

            <!-- Step 5 -->
            <ng-container *ngIf="step === 5">
                <app-step-building-insets-component></app-step-building-insets-component>
            </ng-container>

            <!-- Step 6 -->
            <ng-container *ngIf="step === 6">
                <app-step-building-colouring-component></app-step-building-colouring-component>
            </ng-container>
        </ng-container>
    </aside>
</main>
